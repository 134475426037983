import { Theme as ThemeMUI, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      // padding: '8px 0'
    },
    text: {
      padding: '0',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        textAlign: 'center'
      }
    },
    header: {
      width: '75%',
      margin: 'auto',
      textAlign: 'center'
    },

    radioButton: {
      position: 'absolute',
      right: '8px',
      padding: 0,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: 'fit-content',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      }
    },
    title: {
      color: '#0B060F',
      fontSize: 18,
    },
    phone: {
      color: 'var(--bosscat-blue-600)',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      textDecoration: 'none'
    },

    button: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid',
      padding: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px'
      }
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    img: {
      minHeight: '40px'
    },
    buttonNext: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'white !important',
      backgroundColor: 'var(--bosscat-blue-600)',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid',
      padding: '0 1rem',
      transition: '300ms all',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px'
      },
      '&.Mui-disabled': {
        opacity: .3,
        transition: '300ms all'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)',
      },
      '@global': {
        '.Mui-disabled': {
          opacity: '.5 !important'
        }
      }
    },
    boxes: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1rem 0.2rem',
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '1rem',
      // flexDirection: 'column',
      textAlign: 'left',
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      padding: '8px',
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600)',
        transition: '300ms all',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600)',
          }
        }
      }
    },
    boxSelected: {
      border: '2px solid var(--bosscat-blue-600)',
    }
  })
)
