import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    hammer: {
      width: '20px',
      height: '20px'
    },
    afterRepair: {
      background: 'var(--background-color-secondary)',
      borderRadius: '0 0 16px 16px',
      margin: '8px',
      padding: '8px 12px !important'
    },
    afterRepairTitle: {
      display: 'flex',
      gap: '8px',
      borderRadius: '0 0 16px 16px',
      alignItems: 'center'
    },
    afterRepairFontFamily: {
      fontFamily: 'BetterSansRegular'
    },
    titleText: {
      fontFamily: 'OutfitBold',
      fontWeight: 700,
      width: 'fit-content'
    },
    subTitleText: {
      fontFamily: 'OutfitBold',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '150%',
    },
    text: {
      fontFamily: 'BetterSansRegular',
      color: 'rgba(73, 74, 90, 1)'
    },
    options: {
      display: 'flex',
      gap: '16px',
      float: 'right'
    },
    buttonApprove: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      fontFamily: 'BetterSansRegular',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-color)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      '&:hover': {
        color: 'var(--white-color)',
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    buttonDecline: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      fontFamily: 'BetterSansRegular',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-delete-color)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      '&:hover': {
        color: 'var(--white-color)',
        backgroundColor: 'var(--expired-color)'
      }
    },
    titleContainer: {
      gap: "1rem",
      width: 'fit-content'
    },
    price: {
      color: 'var(--input-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
    }
  })
)
