/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import * as Sentry from '@sentry/react'
import { Punchlist } from 'api'
import { propertyDetailActions } from 'ducks/actions'
import { errorTextTryingTo } from 'helpers'
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { FetchPropertySource7Action, FetchPropertyDetailAction, OrderInsightsAction, propertyDetailType, FetchAppliancesTypesAction, FetchAppliancesBrandsAction, ScanLabelAction, ConfirmApplianceAction, DeleteApplianceAction } from './types'

export function* fetchPropertyDetailAction({ payload, callback }: FetchPropertyDetailAction): SagaIterator {
  let success = false
  try {
    const propertyDetail = yield call(Punchlist.properties.getPropertyDetail, payload.id)

    if (propertyDetail) yield put(propertyDetailActions.setPropertyDetail(propertyDetail))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the property detail'))
    if (callback) yield call(callback, false)
  }
}
export function* fetchPropertySource7Action({ payload, callback }: FetchPropertySource7Action): SagaIterator {
  let success = false
  try {
    const appliances = yield call(Punchlist.properties.getPropertySource7, payload.id)

    if (appliances) yield put(propertyDetailActions.setPropertySource7(appliances))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the property detail'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchAppliancesTypesAction({ payload, callback }: FetchAppliancesTypesAction): SagaIterator {
  let success = false
  try {
    const appliancesTypes = yield call(Punchlist.properties.getApplianceType)

    if (appliancesTypes) yield put(propertyDetailActions.setAppliancesTypes(appliancesTypes))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the appliances types'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchAppliancesBrandsAction({ payload, callback }: FetchAppliancesBrandsAction): SagaIterator {
  let success = false
  try {
    const appliancesBrands = yield call(Punchlist.properties.getApplianceBrands, payload.id)

    if (appliancesBrands) yield put(propertyDetailActions.setAppliancesBrands(appliancesBrands))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the appliances types'))
    if (callback) yield call(callback, false)
  }
}

export function* orderInsights({ payload, callback }: OrderInsightsAction): SagaIterator {
  let success = false
  try {
    yield call(Punchlist.properties.requestOrder, payload.propertyId, payload.item, payload.request)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('order insights'))
    if (callback) yield call(callback, false)
  }
}

export function* scanLabel({ payload, callback }: ScanLabelAction): SagaIterator {
  let success = false
  try {
    const scanLabelResults = yield call(Punchlist.properties.scanLabel, payload)

    success = true
    if (callback) yield call(callback, success, scanLabelResults)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('scan label'))
    if (callback) yield call(callback, false)
  }
}

export function* confirmAppliance({ payload, callback }: ConfirmApplianceAction): SagaIterator {
  let success = false
  try {
    const applianceConfirmed = yield call(Punchlist.properties.confirmAppliance, payload)

    yield put(propertyDetailActions.fetchPropertySource7({ id: payload.propertyId }))
    success = true
    if (callback) yield call(callback, success, applianceConfirmed)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('confirm appliance'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteAppliance({ payload, callback }: DeleteApplianceAction): SagaIterator {
  let success = false
  try {
    yield call(Punchlist.properties.deleteAppliance, payload.applianceId, payload.propertyId)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete appliance'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(propertyDetailType.FETCH_PROPERTY_DETAIL, fetchPropertyDetailAction)
  yield takeLatest(propertyDetailType.FETCH_PROPERTY_SOURCE7, fetchPropertySource7Action)
  yield takeLatest(propertyDetailType.FETCH_APPLIANCES_TYPES, fetchAppliancesTypesAction)
  yield takeLatest(propertyDetailType.FETCH_APPLIANCES_BRANDS, fetchAppliancesBrandsAction)
  yield takeLatest(propertyDetailType.SCAN_LABEL, scanLabel)
  yield takeLatest(propertyDetailType.CONFIRM_APPLIANCE, confirmAppliance)
  yield takeLatest(propertyDetailType.DELETE_APPLIANCE, deleteAppliance)
  yield takeLatest(propertyDetailType.ORDER_INSIGHTS, orderInsights)
}
