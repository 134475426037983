import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { TextFieldLabel } from "components/UI"
import { estimateActions, orderMaintenanceActions, promosActions } from "ducks/actions"
import { getOrderMaintenanceValue } from "ducks/selectors"
import { isEmpty } from "helpers/index"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from './styles'

import { icons } from 'assets'

export interface PromotionalCodeProps {
  // setPromoSuccess: (value: boolean) => void
}

const PromotionalCode: FC<PromotionalCodeProps> = ({ /* setPromoSuccess */ }) => {
  const classes = useStyles()
  const dispatch = useDispatch()


  const [loading, setLoading] = useState(false)

  const [promoCode, setPromoCode] = useState('')
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const handlePromo = () => {
    setLoading(true)
    setErrorMessage(null)

    promoCode && dispatch(estimateActions.applyPromoCode(promoCode, (succ, errorCode) => {
      setLoading(false)
      if (succ) {
        // setPromoSuccess(true)
      } else {
        if (errorCode === "PL-0020") {
          setErrorMessage("This Promo Code has expired. Please enter valid Promo Code.")
        } else {
          setErrorMessage('Wrong promo code. Check your code to apply it.')
        }
      }
    }))

  }

  return (
    <Box>
      <Grid item container className={`${classes.promoCodeContainer}`} spacing={2}>

        <Grid item className={classes.promoCode}>
          <Typography className={classes.promoCodeTitle}>Promo Code</Typography>
          <Box position="relative">
            <TextFieldLabel
              type='text'
              value={promoCode}
              error={!isEmpty(errorMessage)}
              onChange={(event: any) => {
                setPromoCode(event.target.value)
                // saveNewValue('promoCode', event.target.value)
              }}
              placeholder='Enter your Promo Code'
              autoComplete='off'
              className={classes.input}
            /*  onBlur={e => {
               isEmpty(promo) && dispatch(orderMaintenanceActions.setOrderMaintenanceValue({ attr: 'promoCode', value: null }))
             }} */
            />
            <Button className={classes.button} variant="contained" onClick={handlePromo} disabled={isEmpty(promoCode)}>
              {loading ? <CircularProgress size="1.5rem" className={classes.spinner} /> : 'Apply Code'}
            </Button>
          </Box>

        </Grid>
      </Grid>
      {errorMessage &&
        <Grid item>
          <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        </Grid>
      }
    </Box>
  )
}

export default PromotionalCode