import { Insight, PropertyContact } from 'ducks/types'
import axios from '../axios'
import { ApiController } from './types'
import { UpdateRequest } from 'api/types'

const base = '/properties'

const apiController: ApiController = {
  getProperties: async (state, city, zipCode, archived) =>
    await axios
      .get(base, { params: { state, city, zipCode, archived } })
      .then((response) => response.data),

  getPropertyDetail: async (id: string) =>
    await axios.get(`${base}/${id}/details`).then((response) => response.data),

  getPropertySource7: async (id: string) =>
    await axios.get(`${base}/source7Property?homeBaseId=${id}`).then((response) => response.data),

  getApplianceBrands: async (id: number | string) =>
    await axios.get(`${base}/appliance/brands/${id}`).then((response) => response.data),

  confirmAppliance: async (data) => {
    const requestData = {
      brand_id: data.brand_id,
      model_number: data.model_number,
      product_type_data: {},
      product_type_id: data.productTypeId,
      property_uid: data.property_uid,
      recall_number: null,
      serial_number: data.serial_number,
      customer_recall_analysis: [],
      manufactured_year: data.manufacturedYear,
      manufactured_month: data.manufacturedMonth,
      install_date: data.install_date,
      age: data.age,
    };
    return await axios.post(`${base}/${data.propertyId}/appliance/confirm?source7ApplianceId=${data.applianceId}`, requestData).then((response) => response.data)
  },

  scanLabel: async (data) =>
    await axios.post(`${base}/${data.propertyId}/appliance`, {
      "images": data.images,
      "product_type_id": data.product_type_id,
      "property_uid": data.property_uid
    }).then((response) => { console.log(response); return response.data }),

  deleteAppliance: async (applianceId: string, propertyId: string) =>
    await axios.delete(`${base}/${propertyId}/appliance/${applianceId}`).then((response) => response.data),

  getApplianceType: async () =>
    await axios.get(`${base}/appliance/types`).then((response) => response.data),

  getPropertyById: async (propertyId: string) =>
    await axios.get(`${base}/${propertyId}`).then((response) => response.data),

  createProperty: async (request) =>
    await axios.post(base, request).then((response) => response.data),

  updateProperty: async (propertyId: string, request) =>
    await axios
      .patch(`${base}/${propertyId}`, request)
      .then((response) => response.data),

  deleteProperty: async (propertyId: string) =>
    await axios
      .delete(`${base}/${propertyId}`)
      .then((response) => response.data),

  uploadProperties: async (request) =>
    await axios
      // .post(`${base}/upload`, request)
      .post(`${base}/upload`, request, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }) // file.type
      .then((response) => response.data),

  getPropertyContacts: async (propertyId: string) =>
    await axios
      .get(`${base}/${propertyId}/contacts`)
      .then((response) => response.data),

  createPropertyContact: async (
    propertyId: string,
    request: Partial<PropertyContact>
  ) =>
    await axios
      .post(`${base}/${propertyId}/contacts`, request)
      .then((response) => response.data),

  updatePropertyContact: async (
    propertyId: string,
    contactId: string,
    request: UpdateRequest
  ) =>
    await axios
      .patch(`${base}/${propertyId}/contacts/${contactId}`, request)
      .then((response) => response.data),

  deletePropertyContact: async (propertyId: string, contactId: string) =>
    await axios
      .delete(`${base}/${propertyId}/contacts/${contactId}`)
      .then((response) => response.data),

  updatePropertyProject: async (
    propertyId: string,
    projectName: string,
    request: Partial<Insight>
  ) =>
    await axios
      .put(`${base}/${propertyId}/projects/${projectName}`, request)
      .then((response) => response.data),

  getPropertyActivity: async (propertyId: string) =>
    await axios
      .get(`${base}/${propertyId}/activity`)
      .then((response) => response.data),

  getPropertiesActivity: async () =>
    await axios.get(`${base}/activity`).then((response) => response.data),

  requestPropertyRenovation: async (propertyId, request) =>
    await axios.post(`${base}/${propertyId}/renovations/request`, request).then((response) => response.data),

  requestPropertyRoom: async (propertyId, request) =>
    await axios.post(`${base}/${propertyId}/rooms/request`, request).then((response) => response.data),

  requestOrder: async (propertyId, item, request) =>
    await axios.post(`${base}/${propertyId}/projects/${item}/request`, request).then((response) => response.data)
}

export default apiController
