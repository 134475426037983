import { Box, Typography } from '@material-ui/core'

import { Grid } from '@material-ui/core'

import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FC, useEffect, useRef, useState } from 'react'
import { PhotoApplianceModalProps } from './types'
import { Button as UIButton } from 'components/UI'
import useStyles from '../../../../../styles'
import { icons } from 'assets'

const PhotoApplianceModal: FC<PhotoApplianceModalProps> = ({
  open,
  setOpen,
  setOpenAnotherModal,
  setFilePath,
  filePath,
  setOpenManuallyModal
}) => {
  const styles = useStyles()
  const inputRef = useRef<any>(null)
  const [file, setFile] = useState(null)

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const f = event.target.files[0]
      setFile(f)

      if (f) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setFilePath(reader.result)
        }
        reader.readAsDataURL(f)
      }
    }
  }
  
  const uploadPhotoFromGallery = () => {
    if (!file) {
      inputRef.current.click()
    } else {
      setFile(null)
    }
  }

  const openManuallyModal = () => {
    setOpenManuallyModal(true)
    setOpen(false)
  }

  useEffect(() => {
    if(filePath){
        setOpenAnotherModal(true)
        setOpen(false)
    } else {
        setFile(null)
    }
  }, [filePath, setOpenAnotherModal, setOpen])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
      fullWidth
    >
      <Grid container direction="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '1rem' }}
        >
          <Box style={{ width: '24px', height: '24px' }}></Box>
          <Typography className={styles.newApplianceText}>
            ADD NEW APPLIANCES
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setOpen(false)}
          >
            <CloseIcon className={styles.closeButtonModal} />
          </Box>
        </Box>
        <Typography
          className={`${styles.title} ${styles.noTransform}`}
          style={{ textAlign: 'center', marginBottom: '1rem' }}
        >
          Add new appliances to your account to get information and
          notifications for maintenance or replacements
        </Typography>
        <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
          <UIButton
            className={`${styles.newApplianceButton}`}
            startIcon={<icons.CloudUpload style={{ width: '1rem' }} />}
            onClick={uploadPhotoFromGallery}
          >
            <Typography
              className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
            >
              Upload from Photo Gallery
            </Typography>
          </UIButton>
          <UIButton
            className={`${styles.newApplianceButton}`}
            onClick={openManuallyModal}
          >
            <Typography
              className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
            >
              Enter manually
            </Typography>
          </UIButton>
        </Box>
        <input
          ref={inputRef}
          style={{ visibility: 'hidden' }}
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpeg"
          onChange={handleUpload}
        />
      </Grid>
    </Dialog>
  )
}

export default PhotoApplianceModal
