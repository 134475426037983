import { Button } from '@material-ui/core'

import { Box, Typography } from '@material-ui/core'

import { Grid } from '@material-ui/core'

import { Dialog } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { FC } from 'react'
import { AddApplianceModalProps } from './types'
import useStyles from '../../../../../styles'
import { useSelector } from 'react-redux'
import { getPropertyApplianceTypes } from 'ducks/selectors'

const AddApplianceModal: FC<AddApplianceModalProps> = ({
  open,
  setOpen,
  setOpenAnotherModal,
  onSelectType
}) => {

  const appliancesTypes = useSelector(getPropertyApplianceTypes())

  const styles = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
      fullWidth
    >
      <Grid container direction="column">
        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '1rem' }}>
          <Box style={{ width: '24px', height: '24px' }}></Box>
          <Typography className={styles.newApplianceText}>
            APPLIANCES
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" onClick={() => setOpen(false)}>
            <CloseIcon className={styles.closeButtonModal} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
          {appliancesTypes?.product_types?.map((app, i) => (
            <>
              <Box
                onClick={() => {
                  setOpenAnotherModal(true)
                  setOpen(false)
                  onSelectType(app)
                }}
                className={styles.optionApplianceContainer}
              >
                <Typography className={styles.textApplianceOption}>{app.product_type_name}</Typography>
                <KeyboardArrowRight
                  style={{
                    width: '18px',
                    height: '18px',
                    color: 'var(--text-text-tertiary)',
                  }}
                />
              </Box>
              {i + 1 !== appliancesTypes?.product_types?.length && (
                <Box
                  style={{
                    height: 1,
                    backgroundColor: 'var(--bosscat-grey-400)',
                    width: '100%',
                  }}
                />
              )}
            </>
          ))}
        </Box>
      </Grid>
    </Dialog>
  )
}

export default AddApplianceModal
