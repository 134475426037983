import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content min-content auto',
      height: '100%',
      width: '100%',
      margin: 0
    },
    table: {
      paddingRight: '24px',
      [theme.breakpoints.down('lg')]: {
        paddingRight: 0,
        padding: 12
      }
    },
    filters: {
      // width: 'calc(100vw - 300px)',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 0,
        padding: '0 20px !important'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px !important'
      }
    },
    tableComponent: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12,

    },
    tableOff: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none',
      overflow: 'hidden'
    },
    pageChange: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 5,
      background: 'rgba(0,0,0,0.02)'
    },
    button: {
      width: '100%',
      lineHeight: '1rem',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 48
      }
    },
    buttonPaid: {
      backgroundColor: 'var(--button-secondary)',
      '&:hover': {
        backgroundColor: 'var(--button-secondary-hover)'
      }
    },
    buttonView: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--button-focus-color)'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 48
      }
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px'
    },
    filtersTitle: {
      fontFamily: 'LatoBlack',
      fontSize: '18px',
      fontWeight: 900,
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px',
      color: 'var(--text-color)'
    },
    row: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      }
    },
    cellHead: {
      backgroundColor: 'var(--background-color-secondary)',
      color: 'var(--head-text-color)',
      borderWidth: 0,
      fontFamily: 'Lato',
      fontSize: '16px'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0,
      padding: '16px 8px !important'
    },
    valueComponents: {
      display: 'grid',
      paddingRight: '12px !important',
      gridTemplateColumns: 'repeat(5, 1fr)',
      flexWrap: 'wrap',
      // width: 'calc(100vw - 300px)',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        padding: '0 8px !important',
        marginTop: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0 20px!important',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0!important',
      }
    },
    valueItem: {
      gridColumn: 'span 1',
      [theme.breakpoints.down('md')]: {
        //  gridColumn: 'span 2'
        padding: 8
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 5'
      }
    },
    tableContainer: {
      // width: 'calc(100vw - 300px)',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        // width: '100vw'
      }
    },
    itemContainer: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          marginRight: '.7rem !important'
        }
      }
    },
    container: {
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid #eeeeee',
        borderRadius: '12px',
        padding: '0.6rem',
        margin: '1rem 1rem',
        boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)'
      },
      '& >.MuiGrid-item': {
        [theme.breakpoints.down('lg')]: {
          padding: '.2rem',
          width: '100%'
        }
      }
    },
    status: {
      textTransform: 'uppercase',
      fontSize: '16px'
    },
    statusSelect: {
      '& li:first-child': {
        '&:after': {
          content: '""',
          display: 'block',
          borderBottom: '2px solid var(--black-color)',
          borderRadius: '8px',
          transform: 'translateY(12px)',
          width: '100%'
        }
      }
    },
    tableSortLabel: {
      height: 'inherit !important',
      color: 'var(--head-text-color) !important',
      '&:hover': {
        color: 'var(--head-text-color)'
      }
    },
    noMargin: {
      margin: '0px !important'
    },
    leftAlignment: {
      textAlign: 'left'
    },
    alignment: {
      align: 'left',
      width: '120px'
    },
    pageTitle: {
      fontSize: '28px',
      fontFamily: 'OutfitHeavy',
      fontWeight: 900,
      textTransform: 'uppercase',
      padding: '10px 12px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 0px 20px 70px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '58px',
        padding: '7px 0px 10px 55px'
      }
    }
  })
)
