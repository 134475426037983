import storeInfo from 'ducks/store'
import { Appliance, PropertySource7, PropertyDetail, ApplianceType, ApplianceBrands } from 'ducks/types'

export const getPropertyDetail = () => (): PropertyDetail => {
  const { propertyDetail } = storeInfo.store.getState()
  return propertyDetail
}

export const getPropertySource7 = () => (): PropertySource7 | undefined => {
  const { propertyDetail } = storeInfo.store.getState()
  return propertyDetail?.propertySource7
}

export const getPropertyAppliances = () => (): Appliance[] => {
  const { propertyDetail: { propertySource7 } } = storeInfo.store.getState()
  return propertySource7?.appliances || []
}

export const getPropertyApplianceTypes = () => (): ApplianceType => {
  const { propertyDetail: { appliancesTypes } } = storeInfo.store.getState()
  return appliancesTypes || []
}

export const getPropertyApplianceBrands = () => (): ApplianceBrands => {
  const { propertyDetail: { appliancesBrands } } = storeInfo.store.getState()
  return appliancesBrands || []
}

export const getPropertyId = () => (): string => {
  const { propertyDetail: { id } } = storeInfo.store.getState()
  return id
}

export const getPropertyInquiredItems = () => (): string[] => {
  const { propertyDetail: { inquiredItems } } = storeInfo.store.getState()
  return inquiredItems || []
}
