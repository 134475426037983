import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: "16px",
            boxShadow: "0px 0px 1px 0px #0919314F",
            border: "2px solid #F5F6F7",
            width: "32.4%",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        body: {
            padding: "16px",
            backgroundColor: "white",
            borderRadius: "16px 16px 0px 0px",
            height: "100%",
        },
        imageContainer: {
            width: "100%",
            height: "220px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
            borderRadius: "16px",
            overflow: "hidden",
            position: 'relative'
        },
        tagContainer: {
            position: 'absolute',
            top: '1rem',
            right: '1rem'
        },
        image: {
            width: "100%",
            height: "220px",
            borderRadius: "16px",
            objectFit: "cover",
            transition: "transform 3s ease-in-out",
        },
        imgHover: {
            transform: "scale(1.2)",
            transition: "transform 7s ease-in-out",
        },
        discountTag: {

        },
        footer: {
            padding: "16px",
            backgroundColor: "#EFF0F1",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "0px 0px 16px 16px",
        },
        actionButton: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            cursor: "pointer",
            color: "white",
            backgroundColor: "#01AA59",
            borderRadius: "8px",
            boxShadow: "0px 0px 1px 0px #0919314F",
            padding: "16px",
            textDecoration: "none",
            fontFamily: "LatoBold"
        },
        title: {
            fontFamily: "OutfitHeavy",
            textTransform: 'uppercase',
            fontSize: "18px",
            marginBottom: "8px",

        },
        description: {

        },
        logo: {
            height: "43px",
            maxWidth: "156px",
        },

    })
)
