import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      marginTop: '0rem',
      // alignItems: 'center',
      justifyContent: 'center'
    },
    content: {
      maxWidth: theme.breakpoints.values.lg,
      margin: 'auto 0px',
      height: '100%',
      paddingRight: '24px',
      '@global': {
        '.MuiTypography-root': {
          fontFamily: 'LatoBold'
        }
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    containerForm: {
      //maxWidth: "660px",
      width: '100%',
    },
    center: {
      backgroundColor: '#FFFFFF',
      padding: '0 20px'
    },
    title: {
      fontFamily: 'OutfitHeavy',
      color: '#A5965A',
      textTransform: 'uppercase',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important'
        }
      },
      margin: '0.8rem 0 0.8rem 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px'
      }
    },
    subtitle: {
      fontFamily: 'LatoBlack !important',
      fontSize: "18px",
      color: "#0b060f",
      marginTop: '0.8rem'
    },
    bottom: {
      marginBottom: '0.8rem'
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    check: {
      flex: 1,
      paddingRight: '16px',
      margin: '0 !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      minWidth: '146px',
      width: '100%'
    },
    checkContent: {
      display: 'flex',
      justifyContent: 'center'
    },
    propertyContract: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      gap: '1rem'
    },
    roleContainer: {
      display: 'flex',
      rowDirection: 'row',
      gap: '1rem',
    },
    buttonsContainer: {
      /*    position: 'absolute',
         bottom: '1rem',
         [theme.breakpoints.down('sm')]: {
           position: 'static',
         } */
    },
    datePicker: {
      marginTop: '-8px',
    },
  })
)
