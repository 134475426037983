import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    name: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      marginRight: 15,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '@global': {
        '.MuiInput-underline.Mui-error:after': {
          border: '0px',
        },
      },
    },
    signature: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 12px 0px 12px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '@global': {
        '.MuiInput-underline.Mui-error:after': {
          border: '0px',
        },
      },
    },
    cellphone: {
      '@global': {
        '.MuiTypography-body1': {
          fontSize: '14px',
          size: '14px',
        },
      },
    },
    errorField: {
      '&.MuiFormControl-root': {
        border: '2px solid rgba(244, 67, 54, 1)',
        borderRadius: 8,
      },
    },
    successField: {
      '&.MuiFormControl-root': {
        border: '2px solid var(--completed-color)',
        borderRadius: 8,
      },
    },
    title: {
      color: 'var(--head-text-color)',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
    latoPrimaryText: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      fontSize: '18px',
      marginRight: '8px',
      lineHeight: '24px',
      paddingTop: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    consentText: {
      color: 'black',
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    terms: {
      // paddingTop: '20px',
      marginLeft: '2px',
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '24px',
      color: 'var(--bosscat-blue-600)',
      transform: 'translateY(-1px)',
      textDecorationLine: 'underline',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '.2rem !important',
      },
    },
    rigth: {
      justifyContent: 'flex-start',
    },
    termsButton: {
      border: '0px solid',
      textTransform: 'none',
      height: 'fit-content',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--white-color)',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'fit-content !important',
      },
    },
    checkboxContainer: {
      alignItems: 'center',
      display: 'flex',
      gap: '10px',
      flexDirection: 'row-reverse',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'nowrap',
        background: '#FAFDFF',
        borderRadius: 12,
        '@global': {
          '.MuiTypography-caption': {
            [theme.breakpoints.down('sm')]: {
              fontFamily: 'LatoNormal',
            },
          },
        },
      },
      '@global': {
        '.MuiButtonBase-root': {
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          },
        },
        '.MuiButton-label': {
          [theme.breakpoints.down('sm')]: {
            paddingTop: '0 !important',
          },
        },
        '.MuiTypography-root': {
          paddingTop: '0 !important',
          [theme.breakpoints.down('sm')]: {
            marginLeft: '1rem',
            lineHeight: '1rem',
          },
        },
      },
    },
    helperText: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
    },
    helperName: {
      fontWeight: 500,
      margin: '0px 6px',
    },
    helperRow: {
      display: 'flex',
      margin: '8px 16px',
    },
    radioContainer: {
      width: '185px',
    },
    homeownerContainer: {
      flexFlow: 'column',
      marginLeft: '5px',
      marginBottom: '8px',
    },
    callout: {
      backgroundColor: 'var(--bosscat-blue-800)',
      padding: '1rem !important',
      margin: '8px',
      alignItems: 'flex-start',
      borderRadius: '8px !important',
      flexWrap: 'nowrap',
      gap: '.4rem',
      width: '97%',
    },
    calloutIcon: {
      color: 'white',
      fontSize: '1rem',
    },
    calloutText: {
      color: 'white',
      fontFamily: 'LatoNormal',
      lineHeight: '1',
      marginBottom: '.3rem',
    },
  })
)
