import { ConfirmAppliance, ScanLabel } from 'ducks/properties/types'
import { Appliance, ApplianceBrands, ApplianceType, Insight, PropertySource7 } from 'ducks/types'
import { PropertyDetailsActions, propertyDetailType, PropertyDetailType } from './types'

const actions = {
  fetchPropertyDetail: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.FETCH_PROPERTY_DETAIL,
    payload,
    callback
  }),
  fetchPropertySource7: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.FETCH_PROPERTY_SOURCE7,
    payload,
    callback
  }),
  fetchAppliancesTypes: (
    payload: {},
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.FETCH_APPLIANCES_TYPES,
    payload,
    callback
  }),
  fetchAppliancesBrands: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.FETCH_APPLIANCES_BRANDS,
    payload,
    callback
  }),
  orderInsights: (
    payload: { propertyId: string, item: string, request: Insight },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.ORDER_INSIGHTS,
    payload,
    callback
  }),
  scanLabel: (
    payload: ScanLabel,
    callback?: (succ: boolean, result?: any) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.SCAN_LABEL,
    payload,
    callback
  }),
  confirmAppliance: (
    payload: ConfirmAppliance,
    callback?: (succ: boolean, result: any) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.CONFIRM_APPLIANCE,
    payload,
    callback
  }),
  deleteAppliance: (
    payload: { applianceId: string, propertyId: string },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.DELETE_APPLIANCE,
    payload,
    callback
  }),
  setPropertyDetail: (payload: Partial<PropertyDetailType>): PropertyDetailsActions => ({
    type: propertyDetailType.SET_PROPERTY_DETAIL,
    payload
  }),
  setPropertySource7: (payload: PropertySource7 | undefined): PropertyDetailsActions => ({
    type: propertyDetailType.SET_PROPERTY_SOURCE7,
    payload: payload as any
  }),
  setAppliancesTypes: (payload: ApplianceType): PropertyDetailsActions => ({
    type: propertyDetailType.SET_APPLIANCES_TYPES,
    payload
  }),
  setAppliancesBrands: (payload: ApplianceBrands): PropertyDetailsActions => ({
    type: propertyDetailType.SET_APPLIANCES_BRANDS,
    payload
  }),
  resetPropertyDetail: (payload: Partial<PropertyDetailType>): PropertyDetailsActions => ({
    type: propertyDetailType.RESET_PROPERTY_DETAIL,
    payload
  }),
  setPropertyInquiredItems: (payload: string[]): PropertyDetailsActions => ({
    type: propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS,
    payload
  }),
}

export default actions
