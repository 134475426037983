import { Box, Dialog, Grid, Typography } from '@material-ui/core'
import { searsLogo } from 'assets/index'
import React from 'react'
import styles from './styles.module.scss'
import { Loader } from 'components/UI'
import TurnedIn from '@material-ui/icons/TurnedIn'
import Image from '@material-ui/icons/Image'


interface ApplianceImagesModalProps {
    open: boolean
    setOpen: (open: boolean) => void,
    applianceTagImage?: string
    productImage?: string
}

const ApplianceImagesModal: React.FC<ApplianceImagesModalProps> = ({
    open,
    setOpen,
    applianceTagImage,
    productImage
}) => {
    const handleOpenImage = (image: string) => {
        window.open(image, '_blank')
    }
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ style: { borderRadius: '16px', padding: '16px', width: "auto" } }}
            fullWidth
            maxWidth="md"
        >
            <Grid container direction="row" alignItems='center' justifyContent='space-between'>
                <Box className={styles.rowItem}>
                    <Box className={styles.titleContainer}>
                        <TurnedIn className={styles.iconTitle} />
                        <Typography className={styles.imageTitle}>APPLIANCE TAG</Typography>
                    </Box>
                    {applianceTagImage ?
                        <Box onClick={() => handleOpenImage(applianceTagImage)} className={styles.uploadImageContainer}>
                            <img alt="appliance tag" src={applianceTagImage} className={styles.image} />
                        </Box>
                        :
                        <Box className={styles.uploadImageContainer}>
                            <TurnedIn className={styles.uploadIcon} />
                            <Typography align='center' className={styles.uploadText}>
                                APPLIANCE TAG PHOTO
                            </Typography>
                            <Typography align='center' className={styles.uploadSubtitle}>
                                Upload a product image via the BOSSCAT App to get information of the current state of your appliance.
                            </Typography>
                        </Box>}
                </Box>
                {/* <Box className={styles.rowItem}>
                    <Box className={styles.titleContainer}>
                        <Image className={styles.iconTitle} />
                        <Typography className={styles.imageTitle}>PRODUCT IMAGE</Typography>
                    </Box>
                    {productImage ?
                        <Box onClick={() => handleOpenImage(productImage)} className={styles.uploadImageContainer}>
                            <img alt="appliance tag" src={productImage} className={styles.image} />
                        </Box>
                        :
                        <Box className={styles.uploadImageContainer}>
                            <Image className={styles.uploadIcon} />
                            <Typography align='center' className={styles.uploadText}>
                                UPLOAD PRODUCT IMAGE
                            </Typography>
                            <Typography align='center' className={styles.uploadSubtitle}>
                                Upload a product image via the BOSSCAT App to have context of the currenty state of your appliance.
                            </Typography>
                        </Box>}
                </Box> */}
            </Grid>
        </Dialog>
    )
}

export default ApplianceImagesModal