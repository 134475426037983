import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 'calc(100vh - 180px)',
      height: '100vh',
      display: 'grid',
      gap: theme.spacing(1),
    },
    title: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#A5965A',
      marginBottom: '1rem',
      fontSize: "28px",
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem',
      },
    },
    options: {
      display: 'flex',
      // gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1rem',
      },
    },
    option: {
      display: 'grid',
      maxHeight: '500px',
      border: '2px solid rgba(235, 236, 239, 1)',
      gap: theme.spacing(2),
      borderRadius: '16px',
      padding: '16px',
      [theme.breakpoints.up('md')]: {
        height: '100%',
        // maxWidth: '550px',
      },
      gridAutoFlow: 'row',
      gridTemplateRows: 'min-content min-content min-content auto',
    },
    optionImage: {},
    optionTitle: {
      fontSize: 18,
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#0B060F'
    },
    optionSubtitle: {
      color: '#0B060F'
    },
    optionButton: {
      textTransform: 'none',
      alignSelf: 'end',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: "LatoBold",
      fontSize: 18,
    },
    or: {
      display: 'flex',
      fontSize: 18,
      fontFamily: 'LatoBold',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
  })
)
