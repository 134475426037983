import { Image, Page, Text, View } from '@react-pdf/renderer'
import styles from './style'
import { getCategoryIcon, round } from 'helpers/index'
import {
  evaluate,
  loanDepotLogo,
  loanDepotQr,
  priceMatchLogo,
} from 'assets/index'

const total = (item) => {
  if (item.minPriceRange && item.maxPriceRange) {
    if (item.minPriceRange === null || item.maxPriceRange === null)
      return '$'.concat(round(item.totalPrice, 2))

    if (item.minPriceRange === item.maxPriceRange)
      return '$'.concat(round(item.totalPrice, 2))

    if (item.minPriceRange !== item.maxPriceRange)
      return '$'
        .concat(round(item.minPriceRange, 2).slice(0, -3))
        .concat(' - $')
        .concat(round(item.maxPriceRange, 2).slice(0, -3))
  }
  return '$'.concat(round(item.totalPrice, 2))
}

const ItemSectionView = ({ items, groupKey, index, price }) => {
  let total = '$'.concat(round(price, 2))
  const rangePriceMin = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.minPriceRange
          ? obj.minPriceRange
          : 0
        : 0)
    )
  }, 0)

  const rangePriceMax = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.maxPriceRange
          ? obj.maxPriceRange
          : 0
        : 0)
    )
  }, 0)

  if (rangePriceMin && rangePriceMax) {
    if (rangePriceMin === null || rangePriceMax === null)
      total = '$'.concat(round(price, 2))

    if (rangePriceMin === rangePriceMax) total = '$'.concat(round(price, 2))

    if (rangePriceMin !== rangePriceMax)
      total = '$'
        .concat(round(rangePriceMin + (price || 0), 0).slice(0, -3))
        .concat(' - $')
        .concat(round(rangePriceMax + (price || 0), 0).slice(0, -3))
  }

  return (
    <View
      key={index + Math.floor(Math.random() * 1000 + 1)}
      style={[styles.container, styles.topSeparation]}
    >
      <View style={[styles.row, styles.sectionTitle]}>
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <View
            style={{
              ...styles.row,
              ...styles.justifyCenter,
              ...styles.alignCenter,
            }}
          >
            <Image
              src={getCategoryIcon(groupKey, false, true)}
              alt={`${groupKey} category icon`}
              style={{
                width: '10px',
                margin: '0 4px',
              }}
            />
            <Text
              style={[styles.nextArtBold, styles.fontSmall, styles.black600]}
            >
              {groupKey?.replace('_', ' ')}
            </Text>
          </View>
        </View>
      </View>
      {/* <Text style={[styles.nextArtBold, styles.fontSmall, styles.blue700]}>
        <PriceDecimal
          price={total}
          totalStyles={{
            ...styles.nextArtBold,
            ...styles.mv1,
          }}
          decimalsStyles={{
            ...styles.nextArtBold,
            ...styles.mv1,
            height: '100%',
          }}
        />
      </Text> */}
      {items.map((item, indx) => (
        <View key={indx}>
          <ItemView item={item} />
        </View>
      ))}
    </View>
  )
}

const PriceDecimal = ({ price, totalStyles = '', decimalsStyles = '', isRanged = false }) => {
  const decimals = isRanged ? "" : price?.split('.')[price?.split('.').length - 1]
  const priceWithoutDecimals = isRanged ? "" : price?.split('.')[0]

  return (
    <View
      style={{
        // ...styles.row,
        ...styles.priceContainer,
      }}
    >
      {isRanged ?
        <Text style={{ ...styles.total, ...totalStyles }}>
          {price}
        </Text> : (
          <>
            <Text style={{ ...styles.total, ...totalStyles }}>
              {priceWithoutDecimals}
            </Text>

            {price?.includes('.') && (
              <Text style={{ ...styles.decimals, ...decimalsStyles }}>
                .{decimals}
              </Text>
            )}
          </>
        )}
    </View>
  )
}
const ItemView = ({ item }) => {
  return (
    <View style={styles.bodyItem}>
      <View style={[styles.containerHorizontal, styles.justifyBetween]}>
        <View
          style={{
            ...styles.row,
            ...styles.flexStart,
            ...styles.mv1,
          }}
        >
          {item.inspectionReportReference && (
            <Text
              style={{
                ...styles.itemText,
                ...styles.green700,
                ...styles.latoNormal,
                ...styles.mr1,
              }}
            >
              {item.inspectionReportReference}
            </Text>
          )}
          <Text
            style={{
              ...styles.itemText,
              whiteSpace: 'wrap',
            }}
          >
            {item.title}
          </Text>
          <Text
            style={{
              ...styles.ml1,
              ...styles.itemText,
              ...styles.grayColor,
              ...styles.latoNormal,
            }}
          >
            (Qty: {item.quantity})
          </Text>
        </View>
        <Text
          style={{
            ...styles.fontSmall,
            ...styles.nextArtNormal,
            ...styles.mv1,
          }}
        // render={() => total(item)}
        >
          <PriceDecimal
            price={total(item)}
            totalStyles={{
              ...styles.fontSmall,
              ...styles.nextArtNormal,
              ...styles.mv1,
            }}
            decimalsStyles={{
              ...styles.fontXXSmall,
              ...styles.nextArtNormal,
              ...styles.mv1,
            }}
          />
        </Text>
      </View>
      {item.inspectionReportNote && (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={{
              ...styles.fontXSmall,
              ...styles.nextArtNormal,
              ...styles.mv1,
              ...styles.latoBold
            }}
            render={() => 'Notes: '}
          />
          <Text
            style={{
              ...styles.fontXSmall,
              ...styles.nextArtNormal,
              ...styles.mv1,
            }}
          >
            {`${item.inspectionReportNote}`}
          </Text>
        </View>
      )}
      {/*  {item.inspectionReportNote && (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={[styles.itemText, styles.textBold]}
            render={() => 'Notes: '}
          />
          <Text style={[styles.itemText, styles.leftSeparation]}>
            {`${
              !item.inspectionReportNote || item.inspectionReportNote === ''
                ? 'No notes in this item'
                : item.inspectionReportNote
            }`}
          </Text>
        </View>
      )} */}
      {item?.disclaimer ? (
        <View
          style={[
            styles.containerHorizontal,
            styles.content,
            styles.mv1,
            styles.w90,
          ]}
        >
          <Text
            style={[styles.itemText]}
          >{`Disclaimer: ${item.disclaimer}`}</Text>
        </View>
      ) : null}

      {/* <View style={[styles.containerHorizontal, styles.content]}>
        {item.status === 'REJECTED' && (
          <Text
            style={[
              styles.itemText,
              styles.textBold,
              styles.removed,
              styles.ml1,
              styles.mh2,
            ]}
          >
            REMOVED
          </Text>
        )}
      </View> */}
      {item.imageFiles?.length > 0 ? (
        <View style={[styles.content, styles.itemImages]}>
          {item.imageFiles?.map((imgFile, index) => (
            <Image key={index} src={imgFile.fileUrl} style={styles.image} />
          ))}
        </View>
      ) : null}
    </View>
  )
}

const PageHeader = ({ userInfo }) => {
  return (
    <View
      style={{
        ...styles.white,
        ...styles.fontXLarger,
        ...styles.nextArtSemiBold,
        ...styles.textCenter,
        ...styles.page3Header,
      }}
    >
      <Text style={{ ...styles.mb4 }}>YOUR BOSSCAT ESTIMATE FOR</Text>
      <Text
        style={{
          ...styles.white,
          ...styles.font18,
          ...styles.latoBold,
          ...styles.textCenter,
        }}
      >
        {userInfo?.address?.fullAddress}
      </Text>
    </View>
  )
}

const Page3 = ({ userInfo, groupedItems, evaluateItems, isApproved, isFromCanada, totalWithTax }) => {
  let totalPriceNumber = 0
  // Initialize total min and max price sums
let totalMinPrice = 0;
let totalMaxPrice = 0;

Object.keys(groupedItems).forEach((groupKey) => {
  const items = groupedItems[groupKey];

  items.forEach(item => {
    const { minPriceRange, maxPriceRange } = item;

    // Only add to total if both minPriceRange and maxPriceRange are defined
    if (minPriceRange != null && maxPriceRange != null) {
      totalMinPrice += minPriceRange;
      totalMaxPrice += maxPriceRange;
    } else {
      totalMinPrice += item.totalPrice;
      totalMaxPrice += item.totalPrice;
    }
  });
});

// Format the result based on whether prices are the same or different
let formattedPriceRange;
if (totalMinPrice === totalMaxPrice) {
  formattedPriceRange = `$${totalMinPrice.toFixed(2)}${isFromCanada ? ' CAD' : ''}`;
} else {
  formattedPriceRange = `$${totalMinPrice.toFixed(2)} - $${totalMaxPrice.toFixed(2)}${isFromCanada ? ' CAD' : ''}`;
}

  const totalItemsEstimated = Object.keys(groupedItems).reduce((acc, obj) => {
    return acc + groupedItems[obj]?.length
  }, 0)
  const totalEvaluateItems = evaluateItems?.length

  return (
    <Page
      // size={600}
      style={{
        ...styles.backgroundBlue900,
        // paddingBottom: '210px',
      }}
    >
      <View
        style={{
          ...styles.backgroundBlue900,
          ...styles.page3,
          // paddingBottom: '300px',
        }}
        height={410}
      >
        <PageHeader
          userInfo={userInfo}
          totalEvaluateItems={totalEvaluateItems}
          totalItemsEstimated={totalItemsEstimated}
        />

        <View style={styles.bodyItems}>
          <Text
            style={{
              ...styles.nextArtBold,
              ...styles.fontMedium,
            }}
          >
            ESTIMATED ITEMS
          </Text>
          <Text
            style={{
              ...styles.fontSmall,
              ...styles.latoNormal,
              ...styles.grayColor,
            }}
          >
            {totalItemsEstimated >= 0
              ? `${totalItemsEstimated} ${totalItemsEstimated === 1 ? 'Item' : 'Items'
              } ${isApproved ? 'in the project' : 'in the shopping cart'}`
              : ''}
            {totalEvaluateItems >= 0
              ? `; ${totalEvaluateItems} ${totalEvaluateItems === 1 ? 'Item' : 'Items'
              } Require Additional Information`
              : ''}
          </Text>
          {Object.keys(groupedItems).map((groupKey, index) => {
            const items = groupedItems[groupKey]
            const price = items.reduce((acc, obj) => {
              return acc + obj.totalPrice
            }, 0)

            totalPriceNumber += price
            return (
              <ItemSectionView
                key={index}
                groupKey={groupKey}
                index={index}
                items={items}
                price={price}
              />
            )
          })}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '50px',
            }}
          >
            <Text
              style={{
                ...styles.nextArtBold,
                ...styles.mv1,
              }}
            >
              Total:
            </Text>
            <Text>
              <PriceDecimal
                isRanged={formattedPriceRange?.includes('-')}
                price={formattedPriceRange?.includes('-') ? formattedPriceRange : `$${round(totalPriceNumber, 2)}`}
                totalStyles={{
                  ...styles.nextArtBold,
                  ...styles.mv1,
                }}
                decimalsStyles={{
                  ...styles.nextArtBold,
                  ...styles.mv1,
                  height: '100%',
                }}
              />
            </Text>
          </View>
        </View>
        {userInfo.serviceable && (
          <View
            style={{
              ...styles.priceMatchFooter,
              ...styles.justifyCenter,
              ...styles.alignCenter,
            }}
            break={true}
          // height={200}
          >
            <View
              style={{
                position: 'relative',
              }}
            >
              <View
                style={{
                  ...styles.row,
                  ...styles.containerHorizontal,
                  gap: '10px',
                  ...styles.justifyCenter,
                  ...styles.alignCenter,
                }}
              >
                <Image
                  src={priceMatchLogo}
                  style={{ ...styles.priceMatchImage, ...styles.ml1 }}
                />
                <View>
                  <Text
                    style={{
                      ...styles.white,
                      ...styles.fontLarger,
                      ...styles.nextArtSemiBold,
                      ...styles.mb2,
                    }}
                  >
                    PRICE MATCH GUARANTEE
                  </Text>
                  <Text
                    style={{
                      ...styles.white,
                      ...styles.latoNormal,
                      ...styles.fontSmaller,
                      marginTop: '8px',
                    }}
                  >
                    {`Our prices are highly competitive. If you find a lower price from\nanother licensed and insured provider, within a 25-mile radius of the\nproperty that matches the scope of work and materials to be used,\nlet us know and we’ll match that price.`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    </Page>
  )
}

export default Page3
