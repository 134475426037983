import { FC, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, Box, ListItem, Input, debounce, LinearProgress, CircularProgress } from '../../../../../UI'
import Inspections from '../Inspections'
import { EstimateItemProps } from './types'
import useStyles from './styles'

import { icons, requestMoreItems } from '../../../../../../assets'
import { PunchlistFile } from '../../../../../../ducks/types'
import { round, history, ITEM_CATEGORY, roundWithoutCents, ITEM_STATUS, ESTIMATE_STATUS } from '../../../../../../helpers'
import { useIsXsScreen } from '../../../../../../hooks'
import { Counter, Tag } from '../../../../../UI/CustomUI'
import { estimateItemsActions, estimateActions } from '../../../../../../ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Price from 'components/UI/CustomUI/atoms/Price'
import { getEstimate } from 'ducks/selectors'

const EstimateItem: FC<EstimateItemProps> = ({
  item,
  // onRemove,
  showBetterStyle = false,
  // showAlert,
  isPunchlistPlanner = false,
  actions,
  initialOpen = false,
  showPrice = false,
  showPriceRange = false,
  actionsClassName = "",
  overrideTotalPrice,
  showExpand = true,
  showCounter = true,
  image,
  subtitle = "",
  showImages = true,
  actionsInContent,
  className = "",
  listItemDirection = "column-reverse"
  // showRemove = true
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const estimate = useSelector(getEstimate())
  const [xsScreen] = useIsXsScreen()
  const { title, quantity, inspectionReportReference, inspectionReportNote, imageFiles, disclaimer, requestedRepairNote, publicRepairNote, minPriceRange, maxPriceRange } = item
  const [quantityValue, setQuantityValue] = useState(quantity)
  const [loadingQty, setLoadingQty] = useState(false)
  const { id: estimateId } = history.getParamValues()
  const [open, setOpen] = useState(initialOpen)

  const handleClick = () => {
    // if (xsScreen) {
    //   if (showAlert) showAlert()
    // } else {
    setOpen(!open)
    // }
  }

  const fetchEstimateView = () => {
    dispatch(
      estimateActions.fetchEstimate(estimateId, () => {
      })
    )
  }

  const handleCount = (count: number) => {
    setLoadingQty(true)
    item?.estimateId && item?.itemId && dispatch(estimateItemsActions.updateEstimateItem({
      estimateId: item?.estimateId,
      itemId: item?.itemId,
      partialItem: { quantity: count }
    }, () => {
      setLoadingQty(false)
      fetchEstimateView()
    }))
  }

  const onQuantityInputChange = useMemo(
    () => debounce(handleCount, 500),
    [quantity]
  )

  const handleQuantityInputChange = (event: any) => {
    const isInteger = /^[0-9]+$/.test(event.target.value)
    const isHigherThan9999 = parseInt(event.target.value) > 9999
    if (!isInteger || isHigherThan9999 || event.target.value === 0) return
    setQuantityValue(event.target.value)
    if (!event.target.value) return
    onQuantityInputChange(event.target.value)
  }

  const getTotal = (): string => {
    const totalPrice = overrideTotalPrice ? overrideTotalPrice : item.totalPrice
    if (minPriceRange && maxPriceRange && showPriceRange) {

      if (minPriceRange === null || maxPriceRange === null)
        return '$'.concat(round(totalPrice || 0, 2))

      if (minPriceRange === maxPriceRange)
        return '$'.concat(round(totalPrice || 0, 2))

      if (minPriceRange !== maxPriceRange)
        return "$".concat(roundWithoutCents(minPriceRange)).concat(" - $").concat(roundWithoutCents(maxPriceRange))
    }

    return '$'.concat(round(totalPrice || 0, 2))
  }

  useEffect(() => {
    setOpen(initialOpen)
  }, [initialOpen])

  const ActionsContainer = ({ children }: any) => {
    if (!xsScreen) {
      return <Box className={classes.row} style={{ gap: '.5rem' }}>{children}</Box>
    } else {
      return <>{children}</>
    }
  }

  return (
    <Box className={classes.root}>
      <ListItem
        className={`${classes.listItem} ${className}`}
        onClick={() => handleClick()}
        button
        style={{
          backgroundColor: `${open ? 'var(--background-color-secondary)' : 'var(--white-color)'}`,
          flexDirection: listItemDirection
        }}
      >
        {isMobile && actions &&
          <ActionsContainer>
            <Box className={`${classes.actionButton} EstimateItem--actionButton ${actionsClassName} ${classes.row}`}>
              {actions}
            </Box>
          </ActionsContainer>
        }
        <Box className={`${classes.row} ${classes.itemContainer}`}>
          {showExpand && (
            <Box className={`${classes.row} ${classes.expandIcon}`}>
              {open
                ? (
                  <icons.ArrowDropDown className={classes.expand} />
                )
                : (
                  <icons.ArrowRight className={classes.expand} />
                )}
            </Box>
          )}
          <Box className={classes.itemContainer}>
            <Box className={`${classes.row} ${classes.itemHeader}`}>
              <Box className={classes.itemHeaderLeft}>
                <Box className={`${classes.row} ${classes.itemTitle}`}>
                  <Box>
                    <Box className={`${classes.row} ${classes.alignCenter}`}>
                      {image && !xsScreen && (
                        <img src={image} className={classes.itemImage} alt="item icon" />
                      )}
                      {item.category === ITEM_CATEGORY.EVALUATE && <icons.Info htmlColor='#FF5031' className={classes.icon} />}
                      <Typography className={classes.ref}> {inspectionReportReference} </Typography>
                      <Box>
                        <Typography className={classes.item}> {title} </Typography>
                        {subtitle &&
                          <Box>
                            {subtitle}
                          </Box>
                        }
                      </Box>
                      {showImages && !isMobile && <Typography style={{ marginLeft: "1ch" }} className={classes.img}>({imageFiles?.length} <icons.Image htmlColor="#9D9B9F" className={classes.imgIcon} />)</Typography>}
                      {item.status === ITEM_STATUS.REJECTED && estimate?.status === ESTIMATE_STATUS.APPROVED && <Tag type={item?.status} textStyle={{ fontFamily: 'Outfit' }} style={{ marginLeft: "1ch" }} />}
                    </Box>
                    {showCounter &&
                      (isPunchlistPlanner ? (
                        <Box className={classes.quantityContainer} style={{ width: '100%' }} onClick={(e: any) => e.stopPropagation()}>
                          <Box className={classes.quantityTitle}>
                            <Typography className={classes.quantityTitle}>Quantity: </Typography>
                            {/* <Counter
                              initialValue={quantity || 1}
                              onIncrease={handleCount}
                              onDecrease={handleCount}
                              color={showBetterStyle ? 'better' : 'primary'}
                            /> */}
                            {showImages && isMobile && <Typography className={classes.img}>({imageFiles?.length} <icons.Image htmlColor="#9D9B9F" className={classes.imgIcon} />)</Typography>}
                          </Box>
                          <input value={quantityValue} type="number" onChange={handleQuantityInputChange} className={classes.qtyInput} />
                          {loadingQty && <CircularProgress size={20} />}
                        </Box>
                      ) :
                        <Box className={`${classes.quantityContainer} ${classes.row}`}>
                          <Typography className={classes.quantityTitle}>Quantity: <Typography> {quantity}</Typography></Typography>
                          {showImages && isMobile && <Typography className={classes.img}>({imageFiles?.length} <icons.Image htmlColor="#9D9B9F" className={classes.imgIcon} />)</Typography>}
                        </Box>
                      )
                    }
                    {
                      disclaimer && (
                        <Typography variant='body1' className={`${classes.descr}`}>
                          Disclaimer: <span className={classes.italic}> {disclaimer}</span>
                        </Typography>
                      )
                    }
                  </Box>
                </Box>
              </Box>

              {isMobile &&
                <ActionsContainer>
                  {showPrice && (
                    <Box className={classes.price}>
                      <Price price={getTotal()} totalClassName={classes.price} decimalsClassName={classes.price} />
                      {/* <Typography className={classes.price}>{getTotal()}</Typography> */}
                    </Box>
                  )}
                </ActionsContainer>
              }

              {!isMobile &&
                <ActionsContainer>
                  {showPrice && (
                    <Box className={classes.price}>
                      <Price price={getTotal()} totalClassName={classes.price} decimalsClassName={classes.price} />
                      {/* <Typography className={classes.price}>{getTotal()}</Typography> */}
                    </Box>
                  )}
                  {actions &&
                    <Box className={`${classes.actionButton} ${actionsClassName} ${classes.row}`}>
                      {actions}
                    </Box>
                  }
                </ActionsContainer>
              }

            </Box>
            <Box hidden={!open} className={classes.container}>
              {showImages && imageFiles && imageFiles.length > 0 && (
                <Grid item>
                  <Inspections modalTitle={
                    <Box className={classes.inspectionTitleContainer}>
                      <img hidden={isMobile} src={requestMoreItems} alt="request more items" />
                      <Box>
                        <Typography className={classes.inspectionTitle}> {inspectionReportReference} {title} </Typography>
                        <Typography className={classes.inspectionImg}> {imageFiles.length} Images</Typography>
                      </Box>
                    </Box>
                  }
                  >
                    {(imageFiles as unknown) as PunchlistFile[]}
                  </Inspections>
                </Grid>
              )}
              <Grid container className={classes.content}>
                {item?.measurement && <Grid container item className={classes.contentItem}>
                  <Typography className={classes.bold}>Measurements:</Typography>
                  <Typography>{item?.measurement}</Typography>
                </Grid>}
                {item?.inspectionReportNote && <Grid container item className={classes.contentItem}>
                  <Typography className={classes.bold}>Inspection Notes: <Typography>{item.inspectionReportNote}</Typography></Typography>
                </Grid>}
                {item?.fieldNote && <Grid container item className={classes.contentItem}>
                  <Typography className={classes.bold}>Other complementary Content:</Typography>
                  <Typography>{item?.requestedRepairNote}</Typography>
                </Grid>}
                {
                  publicRepairNote && (
                    <Grid container item className={classes.contentItem}>
                      <Typography variant='body1' className={`${classes.descr}`}>
                        <Typography display='inline' className={classes.bold}>Repair Notes: </Typography>
                        {publicRepairNote}
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>
              {actionsInContent && actionsInContent}
            </Box>

          </Box>
        </Box>

      </ListItem>


    </Box>
  )
}

export default EstimateItem
