import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 1rem',
    },
    nextHeavy: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
    },
    subNextHeavy: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: 16,
    },
    nextHeavyHardware: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#A5965A',
      fontSize: 20,
      textAlign: 'center',
    },
    nextHeavyHardware2: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#A5965A',
      fontSize: 24,
      textAlign: 'center',
    },
    subText: {
      fontSize: 16,
      fontFamily: 'Lato',
      textAlign: 'center',
    },
    subText2: {
      fontSize: 12,
      fontFamily: 'Lato',
      textAlign: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto 0px',
    },
    container2: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
      },
    },
    item: {
      flexBasis: '20%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    button: {
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '94%',
        position: 'fixed',
        bottom: 0,
      },
      marginTop: '2rem',
      marginBottom: '10px',
    },
    title: {
      fontSize: 40,
      textAlign: 'center',
      color: "#0B060F",

    },
    bottom: {
      marginBottom: '0.8rem'
    },
    top: {
      marginTop: '0.8rem'
    },
    logoimg: {
      height: '29px',
    },
    images: {
      height: '80px',
      width: '80px',
    },
    marginItem: {
      marginBottom: '2rem',
    },
  })
)
