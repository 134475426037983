import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: '16px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0px',
        backgroundColor: 'white'
      }
    },
    empty: {
    },
    sndContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    sndContent: {
      display: 'flex',
      '@global': {
        h6: {
          fontFamily: 'LatoNormal'
        }
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    primaryText: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontWeight: 900,
      fontSize: '18px',
      marginRight: '8px',
      lineHeight: '24px',
      paddingTop: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
      }
    },
    secondaryText: {
      color: 'var(--bosscat-black-400)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '24px',
      paddingTop: '5px',
      paddingLeft: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '1px',
      }
    }
  })
)
