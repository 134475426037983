import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
    },
    header: {
      padding: '30px 0px',
    },
    banner: {
      padding: '30px 0px',
    },
    title: {
      fontSize: '28px',
      fontFamily: 'OutfitHeavy',
      fontWeight: 900,
      textTransform: 'uppercase',
      padding: '20px 0px 20px 50px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 0px 20px 70px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '1.1',
        padding: '7px 0px 10px 55px',
      },
    },
    titleLeft: {
      [theme.breakpoints.down('sm')]: {
        padding: '7px 0px 10px 18px',
      },
    },
  })
)
