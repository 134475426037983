import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            display: 'flex',
        },
        header: {
            margin: '30px 30px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "@global": {
                "img": {
                    width: 335
                }
            }
        },
        container: {
            maxWidth: '35%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
        error404: {

        },
        title: {
            fontSize: '40px',
            fontFamily: 'OutfitHeavy',
            textTransform: 'uppercase',
            fontWeight: 400
        },
        subtitle: {
            fontSize: '30px',
            fontWeight: 100,
            fontFamily: 'LatoNormal',
        },
        subtitleBold: {
            fontWeight: 700,
            fontFamily: 'Lato',
        },
        img: {
            display: 'flex',
            width: '164px',
            margin: '20px 0px',
            borderRadius: '8px'
        },
        button: {
            margin: '1rem 0',
            fontSize: '16px',
            fontWeight: 400,
            width: '220px',
            padding: '30px 0',
            textTransform: 'none',
            color: 'var(--white-color)',
            backgroundColor: 'var(--bosscat-blue-600)',
            borderRadius: 8,
            '&:hover': {
                background: 'var(--button-focus-color)'
            }
        },
        buttonIcon: {
            width: '16px',
            height: '16px',
        },
    })
)