import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      width: '100%',
      height: '88px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: 'white',
      position: 'sticky',
      bottom: 0,
      zIndex: 4,
      [theme.breakpoints.down('sm')]: {
        height: '112px',
        bottom: 'env(safe-area-inset-bottom)',
        position: 'fixed',
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    line1: {},
    line2: {},
    rowTitle: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px'
      }
    },
    rowButtons: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0',
      flex: 0.7,
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '0px 10px',
        width: 'auto',
        alignItems: 'center',
        flex: 1
      }
    },
    forward: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      }
    },
    selectedItems: {
      // width: '220px',
      height: '54px',
      padding: '0 .5rem',
      color: 'var(--white-color)',
      // backgroundColor: 'var(--repairs-yellow-500)',
      backgroundColor: '#01AA59 !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      width: 'fit-content',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '60px',
        margin: 0,
        padding: '0 0.5rem'
      }
    },
    requestMoreButton: {
      height: '54px',
      padding: '0 .5rem',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      transition: '300ms all',
      maxWidth: 'fit-content',
      '&:hover': {
        color: '#2662C6',
        backgroundColor: 'white !important',
        transition: '300ms all',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },

      [theme.breakpoints.down('sm')]: {
        width: 'fit-content',
        height: '48px',
        borderRadius: 32,
        color: '#0B060F'
      }
    },
    speak: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    icon: {
      width: '15px',
      height: '15px'
    },
    total: {
      fontFamily: 'OutfitBold',
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      }
    },
    price: {
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '24px',
      marginLeft: '4px',
      minWidth: '80px'
    }
  })
)
