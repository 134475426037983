import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Table, Button as UIButton } from 'components/UI'
import {
  getPropertyAppliances,
  getPropertyDetail,
  getTerritory
} from 'ducks/selectors'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from '../../../styles'

import Image from '@material-ui/icons/Image'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { propertyDetailActions } from 'ducks/actions'
import { Appliance } from 'ducks/types'
import { history } from 'helpers/index'
import { useIsXlScreen } from 'hooks/useMediaQuery'
import InquireModal from '../../../Modals/InquireModal'
import SystemInsightRow from '../../../RowComponents/SystemInsightRow'
import AddApplianceModal from './components/AddApplianceModal'
import ApplianceDetail from './components/ApplianceDetail'
import ApplianceImagesModal from './components/ApplianceImagesModal'
import OrderReplacementModal from './components/OrderReplacementModal'
import PhotoApplianceModal from './components/PhotoApplianceModal'
import PhotoSelectedModal from './components/PhotoSelectedModal'
import RiskScoreMeter from './components/RiskScore'
import ScheduleServiceModal from './components/ScheduleServiceModal'
import StartManuallyModal from './components/StartManuallyModal'
import ApplianceItemMobile from './components/ApplianceItemMobile'

const SystemInsights = () => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [xlScreen] = useIsXlScreen()

  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(null)
  const [selectedItem, setSelectedItem] = useState<string>('')
  const [selectedAppliance, setSelectedAppliance] = useState<Appliance | null>(null)
  const [dateUpdating, setDateUpdating] = useState<boolean>(false)
  const [ordering, setOrdering] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [applianceImagesModal, setApplianceImagesModal] = useState<boolean>(false)
  const [applianceDetailModalOpen, setApplianceDetailModalOpen] = useState<boolean>(false)
  const [scheduleServiceModalOpen, setScheduleServiceModalOpen] = useState<boolean>(false)
  const [orderReplacementModalOpen, setOrderReplacementModalOpen] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<any>({ applianceTagImage: '', productImage: '' })
  const [newApplianceModalOpen, setNewApplianceModalOpen] =
    useState<boolean>(false)
  const [photoApplianceModal, setPhotoApplianceModal] = useState<boolean>(false)
  const [photoSelectedModal, setPhotoSelectedModal] = useState<boolean>(false)
  const [manuallyModal, setManuallyModal] = useState<boolean>(false)
  const [filePath, setFilePath] = useState<string | null | ArrayBuffer>(null)
  const [selectedType, setSelectedType] = useState<any>(null)

  const { id: propertyId } = history.getParamValues()

  const property = useSelector(getPropertyDetail())
  const territory = useSelector(getTerritory())
  const propertyAppliances = useSelector(getPropertyAppliances())

  const {
    details: { insights: systemInsights },
  } = property

  const handleOrderReplacement = () => {
    setOrderReplacementModalOpen(true)
    setTimeout(() => {
      setApplianceDetailModalOpen(false)
    }, 10)
    setTimeout(() => {
      setOrderReplacementModalOpen(false)
      window.open(
        'https://www.homedepot.com',
        '_blank'
      )
    }, 3000)
  }

  const handleScheduleService = () => {
    setScheduleServiceModalOpen(true)
    setTimeout(() => {
      setApplianceDetailModalOpen(false)
    }, 10)
    setTimeout(() => {
      setScheduleServiceModalOpen(false)
      window.open(
        'https://searshomeservices.sjv.io/BOSSCAT',
        '_blank'
      )
    }, 3000)
  }

  const handleOpenImagesModal = (images: any) => {
    setApplianceImagesModal(true)
    setSelectedImage({
      applianceTagImage: images[0]?.url || "",
      productImage: images[1]?.url || "",
    })

  }
  const handleSelectType = (type: any) => {
    setSelectedType(type)
  }

  const fetchPropertyAppliances = () => {
    dispatch(propertyDetailActions.fetchPropertySource7({ id: propertyId }))
  }

  const fetchAppliancesTypes = () => {
    dispatch(propertyDetailActions.fetchAppliancesTypes({}))
  }

  const handleSelectAppliance = (appliance: Appliance) => {
    setSelectedAppliance(appliance)
    setApplianceDetailModalOpen(true)
  }

  useEffect(() => {
    fetchPropertyAppliances()
    fetchAppliancesTypes()
  }, [])

  return (
    <Grid
      item
      container
      direction="column"
      style={{ marginTop: '8px', cursor: ordering ? 'wait' : '' }}
      spacing={2}
      className={`${dateUpdating ? styles.cursorLoading : ''}`}
    >
      {propertyAppliances && propertyAppliances.length ? <Grid item container>
        <UIButton
          className={styles.newApplianceButton}
          style={{ marginBottom: '1rem' }}
          startIcon={
            <AddIcon
              style={{ width: '16px', height: '16px' }}
              color="inherit"
            />
          }
          onClick={() => setNewApplianceModalOpen(true)}
        >
          <Typography
            className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
          >
            Add New Appliance
          </Typography>
        </UIButton>
        {xlScreen ? (
          <Box className={styles.containerBox} width="100%">
            <Table style={{ borderCollapse: 'separate' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={styles.cellHead}
                    align="left"
                    style={{ borderTopLeftRadius: '8px' }}
                  >
                    Category / Item
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Risk Score
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Images
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Install Date
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Expected Life
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Product Age
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Estimated Remaining Life
                  </TableCell>
                  <TableCell
                    className={styles.cellHead}
                    style={{ borderTopRightRadius: '8px' }}
                    align="center"
                  >
                    Service & Replacement
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {propertyAppliances?.map((appliance, index) => (
                  <TableRow key={index} className={styles.rowCell} onClick={() => handleSelectAppliance(appliance)}>
                    <TableCell
                      className={styles.cell}
                      style={{
                        fontWeight: 700,
                        borderBottomLeftRadius:
                          index === propertyAppliances.length - 1 ? '8px' : 0,
                      }}
                    >
                      <Box className={styles.iconLabel}>
                        {appliance?.images[0]?.vision_data?.product_type_name ||
                          appliance.product_type_name}
                      </Box>
                      <Box className={styles.brandLabel}>
                        {appliance.brand_name || appliance?.images[0]?.vision_data?.brand_name}
                      </Box>
                    </TableCell>
                    <TableCell
                      className={styles.cell}
                      align="center"
                    >
                      <RiskScoreMeter score={appliance.appliance_risk_failure_score} />
                    </TableCell>
                    <TableCell
                      className={styles.cell}
                      align="center"
                    >
                      <Box onClick={() => {
                        handleOpenImagesModal(appliance.images);
                        setTimeout(() => {
                          setApplianceDetailModalOpen(false)
                        }, 10)
                      }} className={styles.imagesContainer}>
                        <Typography className={styles.imagesLabel}>
                          {appliance?.images?.length || 0}
                        </Typography>
                        <Image className={styles.imagesIcon} />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={styles.cell}
                      align="center"
                    >
                      {appliance?.install_date || '-'}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {`${Math.floor(appliance.expected_life)} ${Math.floor(appliance.expected_life) === 1 ? 'year' : 'years'}` || '-'}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {`${Math.floor(appliance.age)} ${Math.floor(appliance.age) === 1 ? 'year' : 'years'}` || '-'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={styles.cell}
                      style={{
                        color: appliance.remaining_life <= 1
                          ? 'var(--renovation-red-600)'
                          : 'var(--bosscat-green-600)',
                      }}
                    >
                      {`${Math.floor(appliance.remaining_life)} ${Math.floor(appliance.remaining_life) === 1 ? 'year' : 'years'}` || '-'}
                    </TableCell>
                    <TableCell
                      className={styles.cell}
                      style={{
                        borderBottomRightRadius:
                          index === propertyAppliances.length - 1 ? '8px' : 0,
                      }}
                    >
                      <Box className={styles.applianceButtonContainer}>
                        <Button onClick={handleScheduleService} className={`${styles.applianceButton} ${styles.scheduleServiceButton}`}>
                          <Typography className={`${styles.applianceButtonLabel} ${styles.noTransform}`} style={{ color: 'var(--bosscat-blue-800)' }}>
                            {`Schedule\nService`}
                          </Typography>
                        </Button>
                        <Button onClick={handleOrderReplacement} className={`${styles.applianceButton} ${styles.orderReplacementButton}`}>
                          <Typography className={`${styles.applianceButtonLabel} ${styles.noTransform}`} style={{ color: 'var(--bosscat-green-600)' }}>
                            {`Order\nReplacement`}
                          </Typography>
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <InquireModal open={modalOpen} setOpen={setModalOpen} />
          </Box>
        ) : (
          <Grid container item direction="column" style={{ gap: '16px' }}>
            {propertyAppliances?.map((appliance, index) => (
              <ApplianceItemMobile
                key={index}
                handleOpenDetail={handleSelectAppliance}
                appliance={appliance}
                handleOrderReplacement={handleOrderReplacement}
                handleScheduleService={handleScheduleService}
              />
            ))}
          </Grid>
        )}
      </Grid> : <Box className={styles.emptyContainer}>
        <Typography
          className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
          style={{ color: "var(--bosscat-black-400)", marginBottom: "1rem" }}
        >
          Add Appliances, Maintain, Replace and Enjoy
        </Typography>
        <UIButton
          className={styles.newApplianceButton}
          style={{ marginBottom: '1rem' }}
          startIcon={
            <AddIcon
              style={{ width: '16px', height: '16px' }}
              color="inherit"
            />
          }
          onClick={() => setNewApplianceModalOpen(true)}
        >
          <Typography
            className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
          >
            Add New Appliance
          </Typography>
        </UIButton>
      </Box>}
      <ScheduleServiceModal open={scheduleServiceModalOpen} setOpen={setScheduleServiceModalOpen} />
      <OrderReplacementModal open={orderReplacementModalOpen} setOpen={setOrderReplacementModalOpen} />
      <ApplianceImagesModal open={applianceImagesModal} setOpen={setApplianceImagesModal} applianceTagImage={selectedImage.applianceTagImage} productImage={selectedImage.productImage} />
      <ApplianceDetail open={applianceDetailModalOpen} setOpen={setApplianceDetailModalOpen} appliance={selectedAppliance as Appliance} />
      <AddApplianceModal
        open={newApplianceModalOpen}
        setOpen={setNewApplianceModalOpen}
        setOpenAnotherModal={setPhotoApplianceModal}
        onSelectType={handleSelectType}
      />
      <PhotoApplianceModal
        open={photoApplianceModal}
        setOpen={setPhotoApplianceModal}
        setOpenAnotherModal={setPhotoSelectedModal}
        setFilePath={setFilePath}
        filePath={filePath}
        setOpenManuallyModal={setManuallyModal}
      />
      <PhotoSelectedModal
        open={photoSelectedModal}
        setOpen={setPhotoSelectedModal}
        setBack={setPhotoApplianceModal}
        setFilePath={setFilePath}
        filePath={filePath}
        selectedType={selectedType}
        setOpenManuallyModal={setManuallyModal}
      />
      <StartManuallyModal
        open={manuallyModal}
        setOpen={setManuallyModal}
        selectedType={selectedType}
        setOpenPreviousModal={setPhotoApplianceModal}
      />
    </Grid>
  )
}

export default SystemInsights
