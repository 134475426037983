import { createStyles, makeStyles, Theme as ThemeMUI } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      background: 'white',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      height: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        width: '100%'
      }
    },
    clickable: {
      '&:hover': {
        boxShadow: 'none',
      },
      cursor: 'pointer',
    },
    labelContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    label: {
      fontFamily: 'OutfitNormal',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      textDecoration: 'uppercase',
    },
    value: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'OutfitNormal',
      fontSize: '40px',
      fontWeight: 900,
      lineHeight: '150%',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      }
    }
  })
)
