import { ConfirmAppliance, ScanLabel } from 'ducks/properties/types'
import { Appliance, ApplianceBrands, ApplianceType, Insight, PropertySource7, PropertyDetail } from 'ducks/types'

/** TYPES **/
export const propertyDetailType = {
  FETCH_PROPERTY_DETAIL: 'FETCH_PROPERTY_DETAIL',
  FETCH_APPLIANCES_TYPES: 'FETCH_APPLIANCES_TYPES',
  ORDER_INSIGHTS: 'ORDER_INSIGHTS',
  SET_PROPERTY_DETAIL: 'SET_PROPERTY_DETAIL',
  RESET_PROPERTY_DETAIL: 'RESET_PROPERTY_DETAIL',
  SET_PROPERTY_INQUIRED_ITEMS: 'SET_PROPERTY_INQUIRED_ITEMS',
  FETCH_PROPERTY_SOURCE7: 'FETCH_PROPERTY_SOURCE7',
  SET_PROPERTY_SOURCE7: 'SET_PROPERTY_SOURCE7',
  SET_APPLIANCES_TYPES: 'SET_APPLIANCES_TYPES',
  FETCH_APPLIANCES_BRANDS: 'FETCH_APPLIANCES_BRANDS',
  SET_APPLIANCES_BRANDS: 'SET_APPLIANCES_BRANDS',
  SCAN_LABEL: 'SCAN_LABEL',
  CONFIRM_APPLIANCE: 'CONFIRM_APPLIANCE',
  DELETE_APPLIANCE: 'DELETE_APPLIANCE',
}

/** ACTIONS **/
export interface FetchPropertyDetailAction {
  type: typeof propertyDetailType.FETCH_PROPERTY_DETAIL
  payload: { id: string }
  callback?: (succ: boolean) => void
}

export interface FetchPropertySource7Action {
  type: typeof propertyDetailType.FETCH_PROPERTY_SOURCE7
  payload: { id: string }
  callback?: (succ: boolean) => void
}
export interface FetchAppliancesTypesAction {
  type: typeof propertyDetailType.FETCH_APPLIANCES_TYPES
  payload: {}
  callback?: (succ: boolean) => void
}
export interface FetchAppliancesBrandsAction {
  type: typeof propertyDetailType.FETCH_APPLIANCES_BRANDS
  payload: { id: number | string }
  callback?: (succ: boolean) => void
}

export interface OrderInsightsAction {
  type: typeof propertyDetailType.ORDER_INSIGHTS
  payload: { propertyId: string, item: string, request: Insight }
  callback?: (succ: boolean) => void
}

export interface ScanLabelAction {
  type: typeof propertyDetailType.SCAN_LABEL
  payload: ScanLabel
  callback?: (succ: boolean, result?: any) => void
}

export interface ConfirmApplianceAction {
  type: typeof propertyDetailType.CONFIRM_APPLIANCE
  payload: ConfirmAppliance
  callback?: (succ: boolean, result?: any) => void
}

export interface DeleteApplianceAction {
  type: typeof propertyDetailType.DELETE_APPLIANCE
  payload: { applianceId: string, propertyId: string }
  callback?: (succ: boolean) => void
}

export interface SetPropertyDetailAction {
  type: typeof propertyDetailType.SET_PROPERTY_DETAIL
  payload: Partial<PropertyDetailType>
}
export interface SetPropertySource7Action {
  type: typeof propertyDetailType.SET_PROPERTY_SOURCE7
  payload: PropertySource7
}

export interface SetAppliancesTypesAction {
  type: typeof propertyDetailType.SET_APPLIANCES_TYPES
  payload: ApplianceType
}

export interface SetAppliancesBrandsAction {
  type: typeof propertyDetailType.SET_APPLIANCES_BRANDS
  payload: ApplianceBrands
}
export interface ResetSetPropertyDetailAction {
  type: typeof propertyDetailType.RESET_PROPERTY_DETAIL
  payload: Partial<PropertyDetailType>
}

export interface SetPropertyInquiredItemsAction {
  type: typeof propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS
  payload: string[]
}

export type PropertyDetailsActions =
  | FetchPropertyDetailAction
  | FetchPropertySource7Action
  | FetchAppliancesTypesAction
  | FetchAppliancesBrandsAction
  | OrderInsightsAction
  | SetPropertyDetailAction
  | ResetSetPropertyDetailAction
  | SetPropertyInquiredItemsAction
  | SetPropertySource7Action
  | SetAppliancesTypesAction
  | SetAppliancesBrandsAction
  | ScanLabelAction
  | ConfirmApplianceAction
  | DeleteApplianceAction

/** REDUCER **/
export type PropertyDetailType = PropertyDetail
