import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'OutfitHeavy',
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginTop: '1rem',

      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      }
    },
    subtitle: {
      textAlign: 'center',
      marginBottom: '.5rem',
    },
    pass: {
      // paddingTop: '24px'
    },
    caption: {
      color: 'var(--input-color)'
    },
    link: {
      color: 'var(--bosscat-blue-600)'
    },
    icon: {
      // margin: '0 0 2px 0',
      width: '12px',
      height: '12px',
    },
    iconLeft: {
      margin: '0 0 2px 0',
      width: '12px',
      height: '12px',
      transform: 'rotate(180deg)'
    },
    cancel: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    },
    submit: {
      // margin: theme.spacing(3, 0, 4),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    },
  })
)