import { Box, Button, Grid, Typography } from '@material-ui/core'
import { GroupedOrderType } from '../types'
import useStyles from '../styles'
import HouseIcon from '@material-ui/icons/House'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import { useContext } from 'react'
import { DashboardContext } from '../../../context.provider'

const GroupedOrder = ({ groupedOrder }: { groupedOrder: GroupedOrderType }) => {
  const styles = useStyles()

  const { cart, setCart } = useContext(DashboardContext)

  const handleAddCart = (itemId: string) => {
    setCart([...cart, itemId])
  }

  const handleRemoveCart = (itemId: string) => {
    setCart(cart.filter((id) => id !== itemId))
  }

  const getAddedCount = () => {
    let count = 0

    groupedOrder.items.forEach((item) => {
      if (cart.includes(item.itemId)) count++
    })

    return count
  }

  const handleRemoveAll = () => {
    const groupedItemIds = groupedOrder.items.map((item) => item.itemId)
    setCart(cart.filter((id) => !groupedItemIds.includes(id)))
  }

  const handleAddAll = () => {
    const groupedItemIds = groupedOrder.items.map((item) => item.itemId)
    setCart([...cart, ...groupedItemIds])
  }

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Box
          color="var(--text-text-info)"
          display="flex"
          style={{ gap: '4px' }}
          alignItems='center'
        >
          <HouseIcon style={{ width: '20px', height: '20px' }} />
          <Typography className={styles.desc} style={{ fontFamily: 'OutfitHeavy', fontWeight: 900, textTransform: 'uppercase' }}>
            {groupedOrder.address}
          </Typography>
        </Box>
        {getAddedCount() === groupedOrder.items.length ? (
          <Button
            className={styles.titleRemoveAllButton}
            onClick={handleRemoveAll}
          >
            Remove All
          </Button>
        ) : (
          <Button
            startIcon={<CartIcon style={{ width: '16px', height: '16px' }} />}
            className={styles.titleAddAllButton}
            onClick={handleAddAll}
          >
            Add All
          </Button>
        )}
      </Grid>
      <Grid item container direction="column" spacing={2}>
        {groupedOrder.items.map((item) => (
          <Grid item>
            <Box className={styles.groupedOrderItem}>
              <Box>
                <Typography className={`${styles.desc} ${styles.nextArtHeavyFont}`} style={{ color: 'var(--bosscat-black-600)' }}>{item.title}</Typography>
                <Typography className={`${styles.desc} ${styles.nextArtHeavyFont}`} style={{ color: 'var(--bosscat-black-600)' }}>
                  Maintenance Date:{' '}
                  <Typography
                    className={styles.desc}
                    component="span"
                    style={{ color: 'var(--bosscat-black-400)' }}
                  >
                    07-01-2023. 1 month late.
                  </Typography>
                </Typography>
              </Box>
              {cart?.includes(item.itemId) ? (
                <Button
                  className={`${styles.removeCartButton}`}
                  onClick={() => handleRemoveCart(item.itemId)}
                >
                  <Typography className={styles.lgFont}>Remove</Typography>
                </Button>
              ) : (
                <Button
                  className={styles.addCartButton}
                  startIcon={
                    <CartIcon style={{ width: '16px', height: '16px' }} />
                  }
                  onClick={() => handleAddCart(item.itemId)}
                >
                  <Typography className={styles.lgFont}>Add</Typography>
                </Button>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default GroupedOrder
