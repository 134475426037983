import { Button } from '@material-ui/core'

import { Box, Typography } from '@material-ui/core'

import { Grid } from '@material-ui/core'

import { Dialog } from '@material-ui/core'
import { ArrowForward, KeyboardArrowRight } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { FC, useEffect, useState } from 'react'
import useStyles from '../../../../../styles'
import { StartManuallyModalProps } from './types'
import ProgressBar from '../ProgressBar'
import { Autocomplete, STextField } from 'components/UI'
import { ApplianceInfo } from '../PhotoSelectedModal'
import { Button as UIButton } from 'components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { propertyDetailActions } from 'ducks/actions'
import { getPropertyApplianceBrands, getPropertySource7 } from 'ducks/selectors'
import { YEAR_APPLIANCES_OPTIONS, MONTH_OPTIONS } from 'helpers/constants'
import { history } from 'helpers/index'
import { toast } from 'react-toastify'
import Spinner from '../SpinnerLoader'

interface InfoI {
  brand: {
    key: string
    value: string
  },
  model: string,
  serial: string
}

const StartManuallyModal: FC<StartManuallyModalProps> = ({ open, setOpen, setOpenPreviousModal, selectedType }) => {
  const [info, setInfo] = useState<InfoI>({
    brand: {
      key: '',
      value: '',
    },
    model: '',
    serial: '',
  })
  const [installMonth, setInstallMonth] = useState({
    key: '',
    value: '',
  })
  const [installYear, setInstallYear] = useState({
    key: '',
    value: '',
  })
  const [manufacturedMonth, setManufacturedMonth] = useState({
    key: '',
    value: '',
  })
  const [manufacturedYear, setManufacturedYear] = useState({
    key: '',
    value: '',
  })
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const yearOptions = YEAR_APPLIANCES_OPTIONS();

  const [step, setStep] = useState(1)

  const { id: propertyId } = history.getParamValues()


  const dispatch = useDispatch()

  const propertySource7 = useSelector(getPropertySource7())
  const brands = useSelector(getPropertyApplianceBrands())
  const brandsOptions = brands?.brands?.map((brand) => ({ key: brand.brandId, value: brand.brandName }))


  const styles = useStyles()

  const cancelManually = () => {
    setOpen(false)
    setOpenPreviousModal(true)
    setStep(1)
  }

  const handleNextStep = () => {
    if (step === 3) {
      return handleConfirm()
    }
    setStep(prevStep => prevStep + 1)
  }

  useEffect(() => {
    dispatch(propertyDetailActions.fetchAppliancesBrands({ id: selectedType?.product_type_id.toString() }))
  }, [selectedType])

  const clearValues = () => {
    setInfo({
      brand: {
        key: '',
        value: '',
      },
      model: '',
      serial: '',
    })
    setInstallMonth({
      key: '',
      value: '',
    })
    setInstallYear({
      key: '',
      value: '',
    })
    setManufacturedMonth({
      key: '',
      value: '',
    })
    setManufacturedYear({
      key: '',
      value: '',
    })
  }


  const handleConfirm = () => {
    setLoading(true)
    dispatch(propertyDetailActions.scanLabel({
      images: undefined,
      product_type_id: selectedType?.product_type_id,
      propertyId,
      property_uid: propertySource7?.property_uid || ""
    }, (succ, result) => {
      if (succ) {
        dispatch(propertyDetailActions.confirmAppliance({
          applianceId: result.appliance_uid,
          brand_id: Number(info.brand.key),
          brand_name: info.brand.value,
          model_number: info.model,
          productTypeId: selectedType?.product_type_id,
          serial_number: info.serial,
          property_uid: propertySource7?.property_uid || "",
          propertyId,
          manufacturedYear: Number(manufacturedYear.key),
          manufacturedMonth: Number(manufacturedMonth.key),
          install_date: `${`0${installMonth.key}`.slice(-2)}-${installYear.key}`,
        }, (succ) => {
          if (succ) {
            setSuccess(`${info.brand.value} ${selectedType.product_type_name} saved!`)
            setTimeout(() => {
              setOpen(false)
              setSuccess('')
              clearValues()
              setStep(1)
            }, 2000)
          }
          setLoading(false)
        }))
      } else {
        toast.error('Error saving appliance')
        setLoading(false)
      }
    }))
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
      fullWidth
    >
      {success ? <Typography className={styles.successMessage}>{success}</Typography> : <Grid container direction="column">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: '1rem' }}
        >
          <Typography className={styles.newApplianceText}>
            {selectedType?.product_type_name}
          </Typography>
        </Box>
        <ProgressBar percentage={step * 33.33} />
        {step === 1 && <Box>
          <Typography
            style={{ marginBlock: '1rem', fontFamily: "NextArtBold" }}
          >
            MAIN INFORMATION
          </Typography>
          <Autocomplete
            placeholder="Please select..."
            label="Brand"
            value={info.brand}
            options={brandsOptions}
            onChange={(value: any) => {
              setInfo({ ...info, brand: value })
            }}
            className={styles.brandSelect}
          />

          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: '8px', marginTop: '1rem' }}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 900, fontFamily: 'LatoBlack' }}
            >
              Model
            </Typography>
            <STextField
              value={info?.model}
              className={styles.applianceTextField}
              onChange={(event) =>
                setInfo({ ...info, model: event.target.value })
              }
              placeholder="Model"
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: '8px', marginTop: '1rem' }}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 900, fontFamily: 'LatoBlack' }}
            >
              Serial #
            </Typography>
            <STextField
              value={info?.serial}
              className={styles.applianceTextField}
              onChange={(event) =>
                setInfo({ ...info, serial: event.target.value })
              }
              placeholder="Serial number"
            />
          </Box>
        </Box>}
        {step === 2 && <Box>
          <Typography
            style={{ margin: '1rem 0 0.5rem 0', fontFamily: "NextArtBold" }}
          >
            DATE MANUFACTURED
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            Select manufacture date from appliance tag
          </Typography>
          <Box style={{ height: "16px" }} />
          <Autocomplete
            placeholder="Please select month..."
            label="Manufactured Month"
            value={manufacturedMonth}
            options={MONTH_OPTIONS}
            onChange={(value: any) => {
              setManufacturedMonth(value)
            }}
            className={styles.brandSelect}
          />
          <Box style={{ height: "8px" }} />
          <Autocomplete
            placeholder="Please select year..."
            label="Manufactured Year"
            value={manufacturedYear}
            options={yearOptions}
            onChange={(value: any) => {
              setManufacturedYear(value)
            }}
            className={styles.brandSelect}
          />
        </Box>}
        {step === 3 && <Box>
          <Typography
            style={{ margin: '1rem 0 0.5rem 0', fontFamily: "NextArtBold" }}
          >
            INSTALL DATE
          </Typography>
          <Typography style={{ fontSize: "18px" }}>
            Enter date of installation
          </Typography>
          <Box style={{ height: "16px" }} />
          <Autocomplete
            placeholder="Please select month..."
            label="Install Month"
            value={installMonth}
            options={MONTH_OPTIONS}
            onChange={(value: any) => {
              setInstallMonth(value)
            }}
            className={styles.brandSelect}
          />
          <Box style={{ height: "8px" }} />
          <Autocomplete
            placeholder="Please select year..."
            label="Install Year"
            value={installYear}
            options={yearOptions}
            onChange={(value: any) => {
              setInstallYear(value)
            }}
            className={styles.brandSelect}
          />
        </Box>}
        <Box className={styles.buttonsContainer}>
          <UIButton
            fullWidth
            variant="outlined"
            style={{ borderRadius: '8px' }}
            onClick={cancelManually}
            disabled={loading}
          >
            <Typography
              className={`${styles.title} ${styles.noTransform} ${styles.enterManuallyButtonLabel}`}
            >
              Cancel
            </Typography>
          </UIButton>
          <UIButton
            fullWidth
            variant="contained"
            color="primary"
            style={{ borderRadius: '8px' }}
            onClick={handleNextStep}
            disabled={loading}
            endIcon={!loading && <ArrowForward style={{ width: '16px', height: '16px' }} />}
          >
            {loading ? <Spinner color="#fff" size={16} /> : <Typography
              className={`${styles.title} ${styles.noTransform} ${styles.enterManuallyButtonLabel}`}
            >
              Next
            </Typography>}
          </UIButton>
        </Box>
      </Grid>}
    </Dialog>
  )
}

export default StartManuallyModal
