import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { b } from 'msw/lib/glossary-dc3fd077'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 12px 12px'
    },
    logo: {
      marginTop: '20px',
      width: '160px'
    },
    success: {
      marginBottom: '15px',
      width: '200px'
    },
    nextHeavyHardware: {
      fontFamily: 'OutfitHeavy',
      color: '#A5965A',
      fontSize: 28,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    nextArt: {
      fontFamily: 'OutfitHeavy',
      fontSize: 16,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    header: {
      fontFamily: 'Lato',
      fontSize: '18px',
      marginBottom: "2rem",
      //paddingBottom: '12px',
      '& > span': {
        color: 'var(--bosscat-blue-600)'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    desc: {
      fontFamily: 'Lato',
      maxWidth: '360px',
      fontSize: '18px',
      lineHeight: '27px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
      //color: 'rgba(73, 74, 90, 1)'
    },
    desc2: {
      fontFamily: 'LatoNormal',
      maxWidth: '360px',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'rgba(73, 74, 90, 1)',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginBottom: '8px'
      }
    },
    ask: {
      paddingBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginTop: '8px'
      }
    },
    button: {
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      marginTop: '20px',
      marginBottom: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    estimatedTimeContainer: {
      display: 'flex',
      marginTop: '2rem',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '1rem'
      }
    },
    estimatedTimeItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '220px',
      [theme.breakpoints.down('md')]: {
        width: '196px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    estimatedTimeLabel: {
      fontFamily: 'Lato',
      fontSize: '18px',
      lineHeight: '28px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    estimatedTimeDate: {
      fontSize: '20px',
      lineHeight: '30px'
    },
    estimatedTime: {
      textAlign: 'center',
      borderWidth: '2px',
      borderRadius: '8px',
      padding: '8px 16px',
      margin: '8px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[700],
      backgroundColor: 'var(--white-color)',
      color: theme.palette.grey[700],
      width: '220px',
      [theme.breakpoints.down('md')]: {
        width: '196px'
      }
    },
    dashedSeparator: {
      width: '128px',
      [theme.breakpoints.down('md')]: {
        width: '96px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '46px',
        transform: 'rotate(90deg)',
        margin: 18
      },
      border: '2px dashed #AAACBB',
      alignSelf: 'center',
      borderWidth: '0px',
      borderTopWidth: '2px',
      borderColor: theme.palette.grey[400],
      margin: '60px 24px 40px'
    },
    borderBlue: {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light
    },
    expedite: {
      position: 'relative',
      width: '100%',
      maxWidth: '650px',
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(249, 250, 250, 1)',
      borderRadius: '8px',
      padding: '12px 16px',
      justifyContent: 'space-between',
      margin: '12px 0'
    },
    expediteButton: {
      width: '220px',
      height: '48px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important'
      }
    },
    sooner: {
      position: 'absolute',
      top: '-12px',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      borderRadius: '8px',
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: 'var(--hardware-haze-600)',
      backgroundColor: 'var(--scheduling-color-back)',
      padding: '4px 8px',
      [theme.breakpoints.down('sm')]: {
        top: '-16px'
      }
    },
    icon: {
      width: '72px',
      height: '72px',
      marginRight: "16px",
    },
    createAccount: {
      width: '100%',
      maxWidth: '560px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '8px',
      margin: '8px 0 32px 0',
      justifyContent: 'space-around',
      backgroundColor: '#F6F5EF',
      padding: '16px',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      }
    },
    createAccountAsk: {
      fontFamily: 'OutfitBold',
      fontSize: '16px',
      textAlign: "center",
      color: "var(--bosscat-blue-600)",
      paddingBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginTop: '8px'
      }
    },
    createAccountDesc2: {
      fontFamily: 'LatoNormal',
      maxWidth: '360px',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: "center",
      color: '#0B060F',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginBottom: '8px'
      }
    },
    nextStep: {
      textAlign: 'center',
      marginBottom: "1rem",
      maxWidth: '314px',
    },
    createAccountCreateButton: {
      width: '250px',
      float: 'right',
      height: '50px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: '18px',
      margin: '5px',
      lineHeight: '1.2',
      fontFamily: "LatoBold",
      [theme.breakpoints.down('sm')]: {
        margin: '2px',
      }
    },
    createAccountDesc1: {
      fontFamily: 'LatoBold',
      maxWidth: '360px',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#0B060F',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginBottom: '8px'
      }
    },
    createButton: {
      width: '250px',
      float: 'right',
      height: '50px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: '14px',
      margin: '5px',
      lineHeight: '1.2',
      [theme.breakpoints.down('sm')]: {
        margin: '2px',
      }
    },
    videoText: {
      marginTop: '8px'
    },
    video: {
      border: '1px solid var(--gray-color)',
      padding: '4px',
      margin: '8px 0',
      borderRadius: '16px',
      maxWidth: '650px',
      width: '100%',
      height: '300px'
    },
    completeProfile: {
      width: '100%',
      maxWidth: '650px',
      display: 'flex',
      justifyContent: 'space-around',
      gap: '1rem',
      background: '#FAFDFF',
      border: '2px solid #0071A3',
      borderRadius: 16,
      padding: '.7rem',
      alignItems: 'center',
      margin: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        gap: '0',
        margin: '8px 0',
        padding: '8px'
      }
    },
    bold: {
      fontWeight: 'bold',
    },
    grid: {
      display: 'grid',
    },
    letsLogin: {
      width: '100%',
      maxWidth: '361px',
      display: 'flex',
      justifyContent: 'center',
      // gap: '1rem',
      background: '#F6F5EF',
      //border: '2px solid #0071A3',
      borderRadius: 16,
      padding: '.7rem',
      alignItems: 'center',
      margin: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        gap: '0',
        margin: '8px 0',
        padding: '8px'
      }
    },
  })
)
