import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#FFF9EA',
      padding: '.5rem',
      flexDirection: 'column',
      borderRadius: 8,
      display: 'grid',
      gridTemplateAreas: "'title buttons' 'collapse collapse'",
      marginTop: '1rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: "'title' 'input' 'buttons' 'collapse'",
      },
    },
    rootExpanded: {
      gridTemplateAreas: "'title buttons' 'input input' 'images images' 'collapse collapse'",
      gap: '1rem',
    },
    title: {
      fontFamily: 'OutfitBold',
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    subtitle: {
      fontSize: 14,
    },
    bold: {
      fontFamily: 'LatoBold'
    },
    container: {
      // display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      gridArea: 'input',
    },
    images: {
      gridArea: 'images',
    },
    buttonEdit: {
      background: '#FCFCFC',
      color: 'var(--bosscat-blue-600)',
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      height: 40,
      transition: '300ms all',
      padding: '0 1.2rem',
      minWidth: '85px',
      boxShadow:
        '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      '&:hover': {
        backgroundColor: '#FCFCFC',
        transition: '300ms all',
        boxShadow:
          '2px 3px 7px 0px rgba(9, 25, 49, .2), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '.8rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 56,
      },
    },
    loading: {
      
    },
    buttonDelete: {
      background: '#FFEEEA',
      color: '#FF5031',
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      height: 40,
      padding: '0 1.2rem',
      transition: '300ms all',
      boxShadow:
        '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      '&:hover': {
        color: 'white',
        transition: '300ms all',
        background: '#FF5031',
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '.8rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 56,
      },
    },
    buttonsContainer: {
      width: 'fit-content',
      gap: '1rem',
      gridArea: 'buttons',
      justifySelf: 'flex-end',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    header: {
      gridArea: 'title',
    },
    expandButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gridArea: 'collapse',
    },
    expandButton: {
      width: 'fit-content',
      height: 'fit-content',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '@global': {
        '.MuiTypography-root': {
          fontSize: 12,
          fontWeight: 900,
          color: '#2F7BF7',
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.5rem',
        },
      },
    },
    iconOpened: {},
    iconClosed: {
      transform: 'rotate(180deg)',
    },
    buttonRemove: {
      backgroundColor: 'white !important',
      color: 'black',
      // border: '2px solid black',
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
    },
    modal: {
      '@global': {
        '.MuiDialogTitle-root': {
          position: 'absolute',
          right: 0,
        },
        /* '.MuiDialog-paperWidthSm': {
          maxWidth: '500px !important'
        } */
      },
    },
    itemsInput: {
      height: 'fit-content',
      background: 'white',
      '@global': {
        textarea: {
          height: '95px !important',
        },
      },
    },
  })
)
