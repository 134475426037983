import React from "react";

const RiskScoreMeter = ({ score }: { score: number }) => {
    const minScore = 0;
    const maxScore = 10;

    const scoreIsZero = score === 0;

    // Ensure the score stays within bounds
    const normalizedScore = Math.max(minScore, Math.min(maxScore, score));

    // Calculate the rotation for the needle (map score to degrees)
    const angle = (normalizedScore - minScore) / (maxScore - minScore) * 180 - 90;

    return (
        <div style={{ position: "relative", width: 50, height: 30, margin: "auto" }}>
            {/* Arc */}
            <svg width="50" height="30" viewBox="0 0 200 140"> {/* Aumenté la altura del viewBox */}
                <defs>
                    <linearGradient id="gradient" gradientUnits="userSpaceOnUse" x1="0" y1="100" x2="200" y2="100">
                        <stop offset="0%" stopColor={scoreIsZero ? "#c7c7c7" : "green"} />
                        <stop offset="50%" stopColor={scoreIsZero ? "#c7c7c7" : "yellow"} />
                        <stop offset="100%" stopColor={scoreIsZero ? "#c7c7c7" : "red"} />
                    </linearGradient>
                </defs>
                <path
                    d="M10,110 A90,80 0 0,1 190,110"
                    fill="none"
                    stroke="url(#gradient)"
                    strokeWidth="40"
                />
            </svg>

            {/* Needle */}
            {!scoreIsZero && <div
                style={{
                    position: "absolute",
                    top: 9,
                    left: 25,
                    width: 1,
                    height: 15,
                    borderRadius: 8,
                    backgroundColor: "black",
                    transformOrigin: "bottom center",
                    transform: `rotate(${angle}deg) translate(0px, -14px)`,
                }}
            />}

            {/* Score Label */}
            <div
                style={{
                    position: "absolute",
                    top: 8,
                    left: 0,
                    width: "100%",
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                }}
            >
                {scoreIsZero ? "-" : normalizedScore.toFixed(1)}
            </div>
        </div>
    );
};

export default RiskScoreMeter;
