import { FC } from 'react'
import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
} from '../../../../../UI'
import { OrderNowModalProps } from './types'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from '../../styles'
import { useDispatch, useSelector } from 'react-redux'
import { estimatesActions, newEstimateActions, newOrderMaintenanceActions, territoriesActions } from 'ducks/actions'
import { ESTIMATE_STATUS, FORM_TYPE } from 'helpers/constants'
import { NewEstimateType } from 'ducks/newEstimate/types'
import { getNewEstimateValue, getPropertyDetail, getTerritory, getUser, getUserPrimaryEmail } from 'ducks/selectors'
import { useHistory } from 'react-router-dom'

const OrderNowModal: FC<OrderNowModalProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch()

  const styles = useStyles()
  const property = useSelector(getPropertyDetail())
  const user = useSelector(getUser)
  const userMail = useSelector(getUserPrimaryEmail)
  const territory = useSelector(getTerritory())
  const isNar = useSelector(getNewEstimateValue('isNar'))
  const affiliate = useSelector(getNewEstimateValue('affiliate'))

  const { push } = useHistory()

  const handleClose = () => {
    setOpen(false)
  }

  const updateNewEstimateValues = (data: Partial<NewEstimateType>) => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          ...data,
        },
      })
    )
  }

  const handleInspection = () => {
    updateNewEstimateValues({
      formType: FORM_TYPE.CLIENT_INSPECTION_REPORT,
      address: property.address,
      step: 0,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
    })
    dispatch(
      newEstimateActions.createEstimateV2((succ) => {
        push('/estimate?p=2a')
      })
    )
  }

  const handleRenovation = () => {
    updateNewEstimateValues({
      formType: FORM_TYPE.CLIENT_REPAIR_LIST,
      address: property.address,
      step: 0,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
    })

    dispatch(
      newEstimateActions.createEstimateV2((succ) => {
        dispatch(
          estimatesActions.fetchEstimatesServices(
            {
              formType: FORM_TYPE.CLIENT_REPAIR_LIST,
              serviceable: territory?.serviceable || false,
              isNar,
              affiliate,
            },
            () => { }
          )
        )
        dispatch(
          newEstimateActions.updateEstimate(
            { formType: FORM_TYPE.CLIENT_REPAIR_LIST },
            (success, estimate) => {
              if (success && estimate?.id) {
                dispatch(
                  newEstimateActions.setNewEstimateValue({
                    attr: 'formType',
                    value: FORM_TYPE.CLIENT_REPAIR_LIST,
                  })
                )
              }
            }
          )
        )

        push('/estimate?p=2b')
      })
    )
  }

  const handleGetAnEstimate = () => {
    updateNewEstimateValues({
      address: property.address,
      estimate: { createdBy: user.id, status: ESTIMATE_STATUS.NEW },
      mainContact: {
        email: userMail,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: String(user.phone),
        sendEstimate: true,
      },
    })
    push('/estimate?from=property')
  }

  const handleMaintenance = () => {
    dispatch(
      territoriesActions.fetchTerritory(
        property?.address?.zipCode,
        (succ, terr) => {
          dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
              attr: 'serviceable',
              value: terr?.serviceable || false,
            })
          )
          push('/maintenance?from=property')
          dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
              attr: 'propertyAddress',
              value: property.address,
            })
          )
        }
      )
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ padding: 0 }}
      PaperProps={{
        style: {
          borderRadius: '16px 16px 0 0',
          width: '100vw',
          maxWidth: '100vw',
          margin: 0,
          position: 'fixed',
          bottom: 0,
        },
      }}
      fullWidth
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px'}}
      >
        <Box display="flex" justifyContent="flex-end">
          <Button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon style={{ width: '14px', height: '14px' }} />
          </Button>
        </Box>
        <Grid
          item
          container
          style={{
            gap: '16px',
            borderRadius: '0 0 8px 8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow:
              '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
            // justifyContent: 'center'
          }}
        >
          <Grid
            container
            item
            style={{
              gap: '4px',
              width: '100%',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              className={`${styles.lgFont}`}
              style={{ fontWeight: 700, marginLeft: '16px' }}
            >
              Order Now
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg>
            <Button
              fullWidth
              className={styles.navigationButton}
              onClick={handleGetAnEstimate}
            >
              <Typography className={`${styles.lgFont} ${styles.noTransform}`}>
                Estimate
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg>
            <Button
              fullWidth
              className={styles.navigationButton}
              onClick={handleInspection}
            >
              <Typography className={`${styles.lgFont} ${styles.noTransform}`}>
                Inspection
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg>
            <Button
              fullWidth
              className={styles.navigationButton}
              onClick={handleMaintenance}
            >
              <Typography className={`${styles.lgFont} ${styles.noTransform}`}>
                Maintenance
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg>
            <Button
              fullWidth
              className={styles.navigationButton}
              onClick={handleRenovation}
            >
              <Typography className={`${styles.lgFont} ${styles.noTransform}`}>
                Renovation
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default OrderNowModal
