import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: '45vh',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50% !important',
      backgroundAttachment: 'scroll',
      padding: '0 4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem !important',
        minHeight: '60vh',
        // justifyContent: 'flex-start',
      }
    },
    title: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: '42px',
      lineHeight: 1.2,
      marginBottom: '1rem',
      '@global': {
        'p': {
          fontFamily: 'OutfitHeavy',
          fontSize: '42px',
          textTransform: 'uppercase',
          lineHeight: 1.2,
          [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            width: '100%',
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        width: '100%',
      }
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: 700,
      // width: '50%',
      '@global': {
        'p': {
          fontSize: '18px',
          fontWeight: 700,
          // width: '50%',
        }
      },
      [theme.breakpoints.down('sm')]: {
        // fontSize: '32px',
        width: '100%',
      }
    },
    badge: {
      position: 'absolute',
      maxWidth: '300px',
      top: '2.5rem',
      right: '2.5rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    imgAside__container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '7rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        gap: '2rem',
      }
    },
    imgAside: {
      maxWidth: '500px',
      height: '500px',
      alignItems: 'center',
      objectFit: 'cover',
      borderRadius: '16px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    }
  })
)
