import { UpdateRequest } from 'api/types'
import { Estimate, Item, EstimateContact, EstimateProperties } from 'ducks/types'
import { NotificationListContact } from './actions'

/** TYPES **/
export const estimateTypes = {
  FETCH_ESTIMATE: 'FETCH_ESTIMATE',
  SET_ESTIMATE: 'SET_ESTIMATE',
  UPDATE_ESTIMATE_BY_PATH: 'UPDATE_ESTIMATE_BY_PATH',
  UPDATE_ESTIMATE_PROPERTIES: 'UPDATE_ESTIMATE_PROPERTIES',
  APPROVAL: 'APPROVAL',
  SHARE_ESTIMATE: 'SHARE_ESTIMATE',
  APPLY_PROMO_CODE: 'APPLY_ESTIMATE_PROMO_CODE',
  ADD_ITEMS: 'ADD_ITEMS_ESTIMATE',
  REMOVE_ITEMS: 'REMOVE_ITEMS_ESTIMATE',
  ADD_ESTIMATE_TO_NOTIFICATION_LIST: 'ADD_ESTIMATE_TO_NOTIFICATION_LIST',
  REQUEST_MORE_ITEMS: 'REQUEST_MORE_ITEMS_ESTIMATE'
}

export interface ShareEstimateRequest {
  email: string
  role: string
  firstName: string
  lastName: string
}

export interface RequestMoreItemsRequest {
  requestNotesUpdate: string
}

/** ACTIONS **/
export interface FetchEstimateAction {
  type: typeof estimateTypes.FETCH_ESTIMATE
  payload: string
  callback?: (succ: boolean, estimate?: Estimate) => void
}

export interface UpdateEstimateByPathAction {
  type: typeof estimateTypes.UPDATE_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback: (succ: boolean, estimate?: Estimate) => void
}

export interface AddEstimateToNotificationListAction {
  type: typeof estimateTypes.ADD_ESTIMATE_TO_NOTIFICATION_LIST
  callback?: (succ: boolean) => void
  payload?: NotificationListContact
}



export interface RequestMoreItemsAction {
  type: typeof estimateTypes.REQUEST_MORE_ITEMS
  callback?: (succ: boolean) => void
  payload: string
}

export interface ShareEstimateAction {
  type: typeof estimateTypes.SHARE_ESTIMATE
  payload: Partial<ShareEstimateRequest>
  callback?: (succ: boolean) => void
}

export interface ApprovalEstimateAction {
  type: typeof estimateTypes.FETCH_ESTIMATE
  payload: Partial<Estimate>
  callback?: (succ: boolean, status: string, payAtClose: boolean) => void
}

export interface ApplyPromoCodeAction {
  type: typeof estimateTypes.APPLY_PROMO_CODE
  payload: string
  callback?: (succ: boolean, errorCode?: string) => void
}

export interface AddItemsToEstimateAction {
  type: typeof estimateTypes.ADD_ITEMS
  payload: Item[]
  callback?: (succ: boolean) => void
}

export interface SetEstimateAction {
  type: typeof estimateTypes.SET_ESTIMATE
  payload: Estimate
}

export interface UpdateEstimatePayload {
  type: typeof estimateTypes.UPDATE_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback?: (succ: boolean, estimate?: Estimate) => void
}

export interface UpdateEstimatePropertiesAction {
  type: typeof estimateTypes.UPDATE_ESTIMATE_PROPERTIES
  payload: Partial<EstimateProperties>
  callback: (succ: boolean) => void
}


export type EstimateActions =
  | FetchEstimateAction
  | SetEstimateAction
  | UpdateEstimatePayload
  | ApprovalEstimateAction
  | ShareEstimateAction
  | ApplyPromoCodeAction
  | AddItemsToEstimateAction
  | AddEstimateToNotificationListAction
  | UpdateEstimatePropertiesAction

/** REDUCER **/
export type EstimateType = Estimate | null
