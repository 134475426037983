import { ChangeEvent, FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'

import {
  getNewOrderMaintenanceValue,
  getOrderMaintenanceValue,
  getUser,
  isAuthorized,
} from 'ducks/selectors'

import { icons } from 'assets'
import giftImage from 'assets/icons/maintenance/giftImage.png'
// import GiftIcon from 'assets/icons/maintenance/house.svg'
import OwnHomeIcon from 'assets/icons/maintenance/house2.svg'
import roofImage from 'assets/icons/maintenance/roofImage.png'

import ImageBadge from 'components/UI/CustomUI/molecules/ImageBadge'
import { MaintenanceModalsContext } from 'components/pages/Desktop/OrderMaintenance/Dashboard/context'
import { newOrderMaintenanceActions } from 'ducks/actions'
import { CONTACT_ROLE, EMAIL_TYPE, USER_TYPE } from 'helpers/constants'
import { useStepperContext } from 'hooks/useStepperContext'
import { isMobile } from 'react-device-detect'
import styles from './styles.module.scss'
import { GiftIcon, HomeIcon } from './utils'

const GiftSelector: FC = () => {
  const dispatch = useDispatch()
  const address = useSelector(getNewOrderMaintenanceValue('propertyAddress'))
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))
  const { setCanGoForward } = useStepperContext()
  const authorized = useSelector(isAuthorized)
  const user = useSelector(getUser)

  const handleChangeIsAGift = (val: string | boolean) => {

    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValues({
        attrs: {
          propertyAddress: null,
        },
      })
    )
    if (val === 'true') {
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValues({
          attrs: {
            propertyAddress: null,
            homeowner: null,
            promoCode: null,
            promo: null,
          },
        })
      )
    }
    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'gift',
        value: val === 'true',
      })
    )
  }

  useEffect(() => {
    // If it isn't a gift or user is homeowner, we set their information
    (!gift) &&
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValues({
          attrs: {
            propertyAddress: user?.address?.latitude && user?.address?.latitude ? user?.address : null,
            homeowner: {
              firstName: mainContact.firstName,
              lastName: mainContact.lastName,
              phone: mainContact.phone || isMobile ? null : 0,
              email: [{
                emailType: EMAIL_TYPE.PRIMARY,
                email: mainContact.email,
              }],
            },
          },
        })
      )
  }, [gift])

  useEffect(() => {
    isMobile && setCanGoForward(true)
  }, [])

  useEffect(() => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "activeStepProgress", value: 2 }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "totalStepProgress", value: 5 }))
  }, [])


  return (
    <Box>
      <Grid container className={styles.GiftSelector__root}>
        <>
          <Typography className={styles.GiftSelector__title}>
            stress free homeownership is just a few clicks away!
          </Typography>
          <Grid container alignItems="flex-start">
            <RadioGroup
              value={gift}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeIsAGift(e.target.value)
              }
              className={styles.GiftSelector__container}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    checkedIcon={
                      <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                    }
                    icon={<icons.RadioButtonUnchecked />}
                  />
                }
                className={`${styles.GiftSelector} ${gift ? styles.GiftSelector__selected : ''
                  }`}
                style={{ alignItems: 'flex-start' }}
                label={
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={styles.GiftSelector__label}
                  >
                    <GiftIcon />
                    <Typography
                      style={{
                        fontFamily: 'OutfitHeavy',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      A gift
                    </Typography>
                  </Grid>
                }
              />
              <FormControlLabel
                value={false}
                style={{ alignItems: 'flex-start' }}
                control={
                  <Radio
                    checkedIcon={
                      <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                    }
                    icon={<icons.RadioButtonUnchecked />}
                  />
                }
                className={`${styles.GiftSelector} ${!gift ? styles.GiftSelector__selected : ''
                  }`}
                label={
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={styles.GiftSelector__label}
                  >
                    <HomeIcon />
                    <Typography
                      style={{
                        fontFamily: 'OutfitHeavy',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                    >
                      For my own home
                    </Typography>
                  </Grid>
                }
              />
            </RadioGroup>
          </Grid>
        </>

        {/* </Grid> */}
        {/* <Grid item lg={6} xs={12} className={styles.GiftSelector__image}>
        <ImageBadge
          img={showHomeownerText ? roofImage : giftImage}
          alt="pro"
          maxHeight={250}
        />
      </Grid> */}
      </Grid>
    </Box>
  )
}

export default GiftSelector
