import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 'min(90vw, 540px)',
      borderRadius: '16px',
      background: 'white'
    },
    close: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      height: "40px",
      borderRadius: "8px",
      marginLeft: "16px"
    },
    backButton: {
      borderRadius: 8,
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      fontSize: '18px',
      height: '44px',
      fontFamily: 'LatoBold'
    },
    radioButtonContainer: {
      '@global': {
        '.MuiFormGroup-root': {
          gap: '1rem',
        },
        '.MuiFormControlLabel-root': {
          border: '2px solid #F5F6F7',
          width: '100%',
          borderRadius: 8,
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          paddingLeft: '1rem',
          marginRight: '0 !important',
        }
      }
    },
    otherInput: {
      marginTop: '1rem',
      '@global': {
        '.MuiFormControl-root': {
          width: '97%'
        }
      }
    },
    radioButtonContainerFlex: {
      display: 'flex',
      '@global': {
        '.MuiFormGroup-root': {
          flexDirection: 'row',
          gap: '1rem'
        },
        '.MuiFormControlLabel-root': {
          // width: '47%',
        }
      }
    },

    modal: {
      '@global': {
        '.MuiGrid-root': {
          width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: 'inherit'
        }
      }
    },
    detailsInput: {
      width: '100%',
    },
    input: {
      width: '100%',
      height: '80px',
      marginTop: '4px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      '& .MuiInput-underline:before': {
        borderBottomColor: '#FFF'
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#FFF'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFF'
      },
      '&$focused $notchedOutline': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&:hover $notchedOutline': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    formControl: {
      '& .MuiButtonBase-root': {
        height: 'auto'
      }
    },
    text: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: '18px',
      height: '44px',
      fontFamily: 'LatoBold'
    },
    logo: {
      margin: '-70px -50px',
      zIndex: 1500,
      position: 'fixed'
    },
    header: {
      background: 'var(--background-color-secondary)'
    },
    headerText: {
      paddingTop: '36px'
    },
    headerTX: {
      color: 'rgba(114, 116, 141, 1)'
    },
    inputNumber: {
      margin: 0,
      borderRadius: '8px 8px 0 0'
    },
    inputDate: {
      borderTop: '0px',
      borderRight: '0px',
      marginTop: '-1px',
      borderRadius: '0 0 0 8px'
    },
    inputCVV: {
      borderTop: '0px',
      marginTop: '-1px',
      borderRadius: '0 0 8px 0'
    },
    check: {
      display: 'grid',
      justifyContent: 'end'
    },
    checkText: {
      color: 'rgba(114, 116, 141, 1)'
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '16px',
      padding: '8px 16px',
      borderRadius: '8px',
      background: 'var(--expired-color-back)'
    },
    errorColor: { borderColor: 'var(--error-color)' },
    errorTopColor: { borderTopColor: 'var(--error-color)' },
    errorBottomColor: { borderBottomColor: 'var(--error-color)' },
    errorLeftColor: { borderLeftColor: 'var(--error-color)' },
    errorRightColor: { borderRightColor: 'var(--error-color)' }
  })
)
