import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#EAF2FE',
      padding: '.5rem',
      flexDirection: 'column',
      borderRadius: 8,
      display: 'flex',
      gap: '.5rem'
    },
    evaluateItemContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex !important',
        '@global': {
          '.EstimateItem--actionButton': {
            width: 'fit-content'
          },
          '.MuiBox-root:first-child': {
            minHeight: 'fit-content !important',
            // width: 'fit-content'
          }
        }
      },
      '@global': {
        '.MuiBox-root': {
          minHeight: 'fit-content !important',
          // width: 'fit-content'
        }
      }
    },
    itemContainerRequested: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    title: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        fontFamily: 'OutfitBold',
      }
    },
    titleContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }
    },
    subtitle: {
      fontSize: 14,
    },
    container: {
      // display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    button: {
      // background: '#2F7BF7',
      // color: 'white',
      background: 'white',
      color: '#2F7BF7',
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      height: 40,
      transition: '300ms all',
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      [theme.breakpoints.down('sm')]: {
        minWidth: 48,
        height: 48,
      },
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: '2px 3px 7px 0px rgba(9, 25, 49, .2), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
        transition: '300ms all'
      },
      '&:active': {
        backgroundColor: 'white',
        boxShadow: 'none',
        transition: '300ms all'
      },
    },
    blueButton: {
      background: '#2F7BF7',
      color: 'white',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      },
      '&.Mui-disabled': {
        opacity: 0.7
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)',
        boxShadow: '2px 3px 7px 0px rgba(9, 25, 49, .2), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
        transition: '300ms all'
      },
    },
    iconMobile: {
      fontSize: '1rem'
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    expandButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    expandButton: {
      width: 'fit-content',
      height: 'fit-content',
      '&:hover': {
        backgroundColor: 'transparent !important'
      },
      '@global': {
        '.MuiTypography-root': {
          fontSize: 12,
          fontWeight: 900,
          color: '#2F7BF7',
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.5rem',
        }
      }
    },
    iconOpened: {

    },
    iconClosed: {
      transform: 'rotate(180deg)'
    },
    buttonRemove: {
      // border: '2px solid black',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
    },
    modal: {
      '@global': {
        '.MuiDialogTitle-root': {
          position: 'absolute',
          right: 0,
          [theme.breakpoints.down('sm')]: {
            position: 'static',
            paddingBottom: '0'
          }
        },
        /* '.MuiButtonBase-root': {
          [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
            padding: 0
          },
        }, */
        '.MuiGrid-root': {
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            minHeight: 'fit-content'
          }
        },
        '.MuiDialog-container': {
          [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
          }

        },
        '.MuiPaper-root': {
          margin: 0
        }
      },

    },
    requestedDate: {
      color: '#2F7BF7',
      fontSize: 14,
      fontWeight: 700
    }
  })
)
