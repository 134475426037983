import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#A5965A',
      marginBottom: '1.5rem',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important',
        },
      },
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem',
        marginBottom: '.5rem'
      },
    },
    roleTitle: {
      fontFamily: 'LatoBlack',
      fontSize: 18,
      paddingLeft: '8px',
      color: "#0b060f"
    },
    nextArtBold: {
      fontFamily: 'OutfitBold',
      fontSize: 18,
    },
    iconArrow: {
      transform: 'rotate(45deg)',
      fontSize: '1.5rem',
      color: "var(--bosscat-blue-600)",
    },
    subtitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px',
      marginBottom: '0px',
    },
    subtitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        flexWrap: "wrap",
      }
    },
    loginLink: {
      color: 'var(--bosscat-blue-600)',
      borderBottom: '1px solid var(--bosscat-blue-600)',
      textDecoration: 'none',
      fontFamily: 'LatoBold',
      fontWeight: 700,
      marginLeft: '.4rem',
      width: "fit-content",
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        marginLeft: "0"
      }
    },
    options: {
      padding: '8px 8px !important',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1,1fr)',
      }
    },
    option: {
      '&:hover': {
        background: 'var(--row-focus)',
        cursor: 'pointer',
        transition: 'all 300ms',
      },
      padding: '0 0rem 0 .5rem',
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 14,
      transition: 'all 300ms',
      boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
      /* margin: '4px 0',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between', */
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    selected: {
      alignItems: 'center',
      borderColor: '#2F7BF7'
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    iconJob: {
      margin: '8px',
      height: '48px',
      width: '48px',
    },
    smallInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: 'var(--date-color)',
      margin: '8px',
    },
    button: {
      width: '220px',
      float: 'right',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '5px 0px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px',
      },
    },
    buttonBack: {
      width: '220px',
      float: 'right',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'transparent',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px',
      },
      margin: '5px',
      '&:hover': {
        color: "var(--white-color)",
        backgroundColor: 'var(--bosscat-blue-600) !important',
      }
    },
    icon: {
      fontSize: '16px !important',
    },
    selectAddressContainer: {
      margin: 'auto 0',
      width: '100%',
      padding: '0 12px'
    },
    buttonBackMargin: {
      margin: '5px 0',
      [theme.breakpoints.down('sm')]: {
        margin: '5px',
      }
    },
    selectAddress: {
      display: 'block',
      '@global': {
        '> .MuiGrid-root': {
          padding: '4px',
          maxWidth: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: "block"
      }
    },
    unitNumber: {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        '@global': {
          h6: {
            margin: 0,
            fontSize: 16,
          },
        },
      }
    },
    locationWarning: {
      display: 'flex',
      gap: theme.spacing(2),
      borderRadius: '8px',
      padding: '12px 16px',
      marginBottom: '24px'
    },
    locationWarningBackground: {
      border: '2px solid ' + theme.palette.primary.light,
    },
    locationWarningText1: {
      color: theme.palette.primary.dark,
    },
    locationWarningText2: {
      fontFamily: 'LatoLight',
      fontWeight: 700,
    },
    locationWarningLink: {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    locationWarningIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    addressError: {
      background: '#FFF4F4',
      border: '1px solid #E01F1F',
      padding: '1rem',
      borderRadius: '8px',
      margin: '4px 8px',
      width: '100%'
    },
    addressErrorSubtitle: {
      fontSize: 16,
      fontFamily: 'LatoNormal'
    },
    titleLabel: {
      fontFamily: 'LatoBlack',
      lineHeight: '24px',
      fontSize: 18,
      color: "#0B060F"
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    professionalTypeOptions: {
      display: 'flex',
      "@global": {
        '.MuiFormGroup-root': {
          width: '100%',
          gap: '1rem'
        }
      }
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      position: "sticky",
      bottom: 0,
      left: 0,
      width: "100%",
      maxWidth: "1250px",
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '1rem',
        background: 'var(--white-color)',
        flexWrap: 'nowrap',
      }
    },
    mobileContainer: {
      paddingBottom: '6rem',
    },
    container: {

    }
  })
)
