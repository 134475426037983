import { createStyles, makeStyles, Theme } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // padding: '20px 0px',
      borderRadius: '16px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 16px',
      },
    },
    spacedItems: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px',
      borderRadius: '8px',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
      },
      '&:active': {
        backgroundColor: 'var(--row-active)',
      },
    },
    horizontalContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '633px',
      // justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    cardLogoContainer: {
      display: 'flex',
      width: '64px',
      height: '32px',
      padding: '8px 7px',
      marginRight: '16px',
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[100],
    },
    cardLogo: {
      width: 34,
    },
    cardInformationContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '500px',
      justifyContent: 'space-between',
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    cardType: {
      fontFamily: 'Lato !important',
      marginRight: '4px',
    },
    cardInformation: {
      fontSize: '16px',
      color: theme.palette.grey[700],
      fontFamily: 'LatoLight',
    },
    selfEnd: {
      justifySelf: 'flex-end',
    },
    w100: {
      maxWidth: '633px',
      width: '100%',
      '@global': {
        '.MuiButtonBase-root': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 20px',
      },
    },
    addPaymentMethodButton: {
      width: '220px !important',
      height: '44px !important',
      borderRadius: '32px',
      borderColor: 'var(--bosscat-blue-600)',
      marginTop: '36px',
      color: 'var(--bosscat-blue-600)',
      '&:hover': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal',
      marginTop: '1rem',
    },
    close: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      borderColor: 'transparent',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal',
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '8px',
      padding: '8px 16px !important',
      borderRadius: '8px',
      background: 'var(--expired-color-back)',
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)',
      },
    },
    logo: {
      margin: '-70px -50px',
      zIndex: 1500,
      position: 'fixed',
    },
    header: {
      background: 'var(--background-color-secondary)',
      textAlign: 'center',
    },
    headerText: {
      paddingBottom: '8px',
    },
    rootModal: {
      maxWidth: '345px',
      borderRadius: '16px',
    },
    cardHolder: {
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      margiTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      '@global': {
        '.MuiInputBase-input': {
          fontFamily: 'LatoNormal',
        },
      },
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    cardFieldContainer: {
      border: '2px solid #EBECEF',
      borderRadius: '8px',
    },
    cardNumber: {
      borderBottom: '2px solid #EBECEF',
    },
    cardExpiry: {
      borderRight: '2px solid #EBECEF',
    },
    cardField: {
      width: '100%',
      padding: '12px 20px',
      display: 'grid',
      alignItems: 'center',
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    content: {
      paddingBottom: '8px',
    },
    paymentMethod: {
      border: '2px solid #EBECEF',
      margin: '.5rem 0',
      padding: '0 1rem',
    },
    newCardTitle: {
      marginBottom: '1rem',
      fontFamily: 'OutfitBold',
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    button: {
      color: 'var(--bosscat-blue-600)',
      background: '#FAFBFF',
      borderRadius: '32px',
      textTransform: 'inherit',
      width: '100%',
    },
    addNew: {
      // border: '2px solid #EBECEF',
      padding: '0 1rem',
      borderRadius: 0,
      background: 'transparent',
      textAlign: 'left',
      justifyContent: 'flex-start',
      color: 'var(--bosscat-black-600)',
      fontSize: '18px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    creditCardIcon: {
      width: '1rem',
    },
    modal: {
      padding: '1rem',
    },
    newCardContainer: {
      display: 'flex',
      width: '100%',
    },
  })
)
