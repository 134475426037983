import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'baseline',
      color: theme.palette.primary.dark
    },
    title: {
      fontFamily: 'OutfitHeavy',
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '48px',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    subTitle: {
      fontFamily: 'OutfitHeavy',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    titleBlack: {
      fontFamily: 'LatoBold',
      fontSize: '32px'
    },
    passTitle: {
      fontFamily: 'Lato',
      paddingTop: '20px'
    },
    form: {
      width: '100%',
      maxWidth: '400px',
      marginTop: theme.spacing(2),
    },
    grid: {
      alignItems: 'center'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      },
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    tryAnotherButton: {
      // margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      background: 'var(--white-color)',
      color: 'var(--bosscat-blue-700)',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
        color: 'var(--white-color)',
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important',
        color: 'var(--white-color)',
      },
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    pass: {
      paddingTop: '24px'
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '400px',
      margin: 'auto'
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '400px',
      marginTop: theme.spacing(4),
    },
    borderContainer: {
      border: '2px solid var(--bosscat-blue-600)',
      borderRadius: 8,
      padding: theme.spacing(2)
    },
    noAccountContainerTitle: {
      marginBottom: theme.spacing(1),
      width: '100%'
    },
    actionLink: {
      color: 'var(--bosscat-blue-600)',
      textDecoration: 'underline',
      fontSize: '18px',
      lineHeight: '27px',
    },
    icon: {
      margin: '0 0 2px 0',
      width: '12px',
      height: '12px'
    },
    hide: {
      display: 'none'
    }
  })
)
