import { Theme as ThemeMUI, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      padding: '8px 0'
    },
    text: {
      padding: '0',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      textAlign: 'left'
    },
    faqTitle: {
      textAlign: 'left',
      fontSize: 18,
      fontFamily: 'Lato',
      fontWeight: 700,
      marginTop: 20
    },
    question: {
      fontSize: '16px',
      // fontFamily: 'OutfitBold',
      lineHeight: '30px',
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    accordion: {
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      borderRadius: 8,
      border: '2px solid #F5F6F7',
      '&:before': {
        content: 'none !important'
      },
      '@global': {
        '.MuiAccordionSummary-root': {
          height: '100%'
        },
        '.MuiAccordionDetails-root .MuiTypography-root': {
          textAlign: 'left'
        }
      }
    },
    button: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid',
      padding: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px'
      }
    }
  })
)
