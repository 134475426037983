import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
    createStyles({
        portfolioView: {
            width: '100%',
            height: '48px',
            display: 'flex',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--bosscat-blue-900)',
            gap: '1rem',
            [theme.breakpoints.down('md')]: {
                height: '60px',
            }
        },
        active: {
            fontFamily: 'OutfitHeavy',
            textTransform: 'uppercase',
            fontWeight: 900,
            fontSize: '20px',
            lineHeight: '150%',
            [theme.breakpoints.down('xs')]: {
                lineHeight: 1,
                marginTop: '1rem',
            },
        },
        container: {
            position: 'relative',
        },
        body: {
            width: '100%',
            height: '100%',
            backgroundColor: "#FFF3D6",
            padding: "16px",
            [theme.breakpoints.down('md')]: {
                paddingTop: '1rem',
            }
        },
        buttons: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "0px 0px 1px 0px #0919314F",
            padding: "8px",
            borderRadius: "8px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",

            }
        },
        blackButton: {
            width: "100%",
            fontSize: "18px",
            color: "white",
            backgroundColor: "#303030",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            marginLeft: "8px",
            borderRadius: "8px",
            cursor: "pointer",
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                marginTop: "16px",
            }
        },
        buttonsTitle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            whiteSpace: "nowrap",
            marginLeft: "8px",
            marginRight: "8px",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                justifyContent: "space-between",
            }
        },
        filters: {
            color: "white",
            backgroundColor: "var(--bosscat-blue-900)",
            borderRadius: "16px",
            padding: "16px",
            border: "1px solid #F5F6F7",
            marginTop: "16px"
        },
        selectors: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            marginTop: "16px",
            position: "relative",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
            }
        },
        selector: {
            width: "100%",
            gap: "4px",
        },
        partners: {
            marginTop: "16px",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            flexWrap: "wrap"
        },
        autocompleteLabel: {
            fontSize: '18px',
            fontFamily: 'LatoNormal',
            fontWeight: 700,
            lineHeight: '24px',
        },
        autocompleteContainer: {
            backgroundColor: "white"
        },
        downloadAppButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            '@global': {
                button: {
                    textTransform: 'capitalize',
                    fontFamily: 'LatoBold',
                    fontSize: 18,
                },
            },
        },
        link: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            color: "#000",
            fontFamily: "LatoBold",
            fontSize: "18px",
        }
    })
)
