import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 24,
      right: 0,
      position: 'absolute',
      margin: '6px 16px 4px'
    },
    itemsBox: {
      padding: '8px 0 32px'
    },
    title: {
      display: 'flex',
      gap: '12px',
      cursor: 'pointer',
      padding: '0px 12px',
      marginBottom: '16px',
      alignItems: 'center',
      backgroundColor: 'rgba(250, 251, 255, 1)',
      borderRadius: '8px'
    },
    titleText: {
      fontFamily: 'OutfitBold',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '150%',
      color: 'var(--input-text)',
      marginRight: '10px'
    },
    items: {
      padding: '0 8px'
    },
    iconCategory: {
      color: 'var(--head-text-color)',
      margin: '18px 8px',
      width: '22px',
      height: '22px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }
  })
)
