import { Box, Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FC, useState } from 'react'
import { STextField, Button as UIButton } from 'components/UI'
import useStyles from '../../../../../styles'
import { PhotoSelectedModalProps } from './types'
import {
  Bookmark,
  CameraAlt,
  CheckCircle,
  Edit,
  FlipToBack,
  FlipToFront,
} from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { propertyDetailActions } from 'ducks/actions'
import { history } from 'helpers/index'
import Spinner from '../SpinnerLoader'

export interface ApplianceInfo {
  brand: string
  model: string
  serial: string
  brandId?: string
  applianceId?: string
  property_uid?: string
}

const PhotoSelectedModal: FC<PhotoSelectedModalProps> = ({
  open,
  setOpen,
  filePath,
  setBack,
  setFilePath,
  selectedType,
  setOpenManuallyModal
}) => {
  const styles = useStyles()
  const [confirm, setConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<string>('Scanning...')
  const [finished, setFinished] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [success, setSuccess] = useState<string>("")
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false)
  const [scanError, setScanError] = useState<boolean>(false)
  const [confirmError, setConfirmError] = useState<boolean>(false)
  const [info, setInfo] = useState<ApplianceInfo>({
    brand: '',
    model: '',
    serial: '',
  })
  const { id: propertyId } = history.getParamValues()

  const dispatch = useDispatch();

  const cancelUpload = () => {
    setOpen(false)
    setBack(true)
    setFilePath(null)
    setLoading(false)
    setConfirm(false)
    setFinished(false)
    setLoadingText('Scanning...')
    setConfirmError(false)
    setScanError(false)
  }

  const handleEnterManually = () => {
    setOpen(false)
    setBack(false)
    setFilePath(null)
    setLoading(false)
    setConfirm(false)
    setFinished(false)
    setLoadingText('Scanning...')
    setConfirmError(false)
    setScanError(false)
    setOpenManuallyModal(true)
  }

  const handleConfirm = () => {
    setConfirm(true)
    setLoading(true)
    setScanError(false)
    handleScanLabel()
  }

  const editAppliance = () => {
    setEditMode(true)
    setFinished(false)
  }

  const handleScanLabel = () => {
    const imageWithoutPrefix = (filePath as string)?.replace(
      'data:image/jpeg;base64,',
      ''
    ).replace('data:image/png;base64,', '');
    const data = {
      images: [{
        content: imageWithoutPrefix,
        description: "Label Image",
        read_label: true,
      }],
      propertyId: propertyId,
      product_type_id: selectedType?.product_type_id as any,
      property_uid: null,
    }
    setTimeout(() => {
      setLoadingText(
        'We are processing your request.\nThis can take up to 30 seconds'
      )
    }, 2000)
    dispatch(propertyDetailActions.scanLabel(data, (succ, result) => {
      if (succ) {
        setInfo({
          brand: result?.images?.[0]?.vision_data.brand_name || '',
          model: result?.images?.[0]?.vision_data.model_number || '',
          serial: result?.images?.[0]?.vision_data.serial_number || '',
          brandId: result?.images?.[0]?.vision_data.brand_id || '',
          applianceId: result?.appliance_uid || '',
          property_uid: result?.property_uid || '',
        })
        setLoading(false)
        setFinished(true)
      } else {
        setScanError(true)
        setLoading(false)
        setLoadingText('Scanning...')
      }
    }))
  }

  const handleConfirmAppliance = () => {
    setLoadingConfirm(true)
    dispatch(
      propertyDetailActions.confirmAppliance({
        applianceId: info?.applianceId as string,
        brand_id: info?.brandId as any,
        brand_name: info?.brand,
        propertyId: propertyId,
        model_number: info?.model,
        productTypeId: selectedType?.product_type_id as any,
        serial_number: info?.serial,
        property_uid: info?.property_uid as any,
      }, (succ, result) => {
        if (succ) {
          setSuccess(`${info?.brand} ${selectedType?.product_type_name} saved!`)
          setTimeout(() => {
            setOpen(false)
            setSuccess("")
            setFilePath(null)
            setLoading(false)
            setConfirm(false)
            setFinished(false)
            setLoadingText('Scanning...')
          }, 2000)
        } else {
          setConfirmError(true)
        }
        setLoadingConfirm(false)
      })
    )

  }
  return (
    <Dialog
      open={open}
      PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
      fullWidth
    >
      {
        scanError ?
          <Grid container direction="column" alignItems='center'>
            <Typography
              className={styles.errorMessage}
            >
              PROCESSING ERROR
            </Typography>
            <Typography
              className={styles.errorDescription}>
              {`Sorry, we couldn't process your data.\nPlease retry or enter you data manually`}
            </Typography>
            <Box className={styles.buttonsContainer}>
              <UIButton
                fullWidth
                variant="outlined"
                style={{ borderRadius: '8px' }}
                onClick={handleEnterManually}
              >
                <Typography
                  className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                >
                  Enter Data Manually
                </Typography>
              </UIButton>
              <UIButton
                fullWidth
                variant="contained"
                color="primary"
                style={{ borderRadius: '8px' }}
                onClick={handleConfirm}
                disabled={loading}
              >
                <Typography
                  className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                >
                  Retry
                </Typography>
              </UIButton>
            </Box>
          </Grid> : confirmError ?
            <Grid container direction="column" alignItems='center'>
              <Typography
                className={styles.errorMessage}
              >
                NO APPLIANCE FOUND
              </Typography>
              <Typography
                className={styles.errorDescription}>
                {`Sorry, we couldn't process your data.\nPlease retry or enter you data manually`}
              </Typography>
              <UIButton
                fullWidth
                variant="outlined"
                style={{ borderRadius: '8px' }}
                onClick={cancelUpload}
              >
                <Typography
                  className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                >
                  Cancel
                </Typography>
              </UIButton>
            </Grid>
            :
            success ?
              <Typography className={styles.successMessage}>{success}</Typography>
              :
              <Grid container direction="column">
                <Box
                  display="flex"
                  justifyContent={finished ? 'space-between' : 'center'}
                  alignItems="center"
                  style={{ marginBottom: '1rem' }}
                >
                  {finished && <Box style={{ width: '24px', height: '24px' }} />}
                  <Typography className={styles.newApplianceText}>
                    APPLIANCE TAG
                  </Typography>
                  {finished && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={cancelUpload}
                    >
                      <CloseIcon className={styles.closeButtonModal} />
                    </Box>
                  )}
                </Box>
                <Grid item>
                  {filePath && (
                    <img
                      src={filePath as string}
                      alt="Appliance"
                      className={styles.appliancePhoto}
                    />
                  )}
                </Grid>
                {!confirm ? (
                  <Box className={styles.buttonsContainer}>
                    <UIButton
                      fullWidth
                      variant="outlined"
                      style={{ borderRadius: '8px' }}
                      onClick={cancelUpload}
                    >
                      <Typography
                        className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                      >
                        Cancel
                      </Typography>
                    </UIButton>
                    <UIButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: '8px' }}
                      onClick={handleConfirm}
                    >
                      <Typography
                        className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                      >
                        Confirm and Upload
                      </Typography>
                    </UIButton>
                  </Box>
                ) : loading ? (
                  <Box className={styles.loadingSection}>
                    <Typography
                      color="primary"
                      align="center"
                      className={`${styles.title} ${styles.noTransform}`}
                    >
                      {loadingText}
                    </Typography>
                  </Box>
                ) : finished ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.finishSection}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" style={{ gap: '.4rem' }}>
                        <Bookmark style={{ color: 'var(--bosscat-black-400)' }} />
                        <Typography
                          align="center"
                          className={`${styles.title} ${styles.noTransform}`}
                          style={{
                            marginBottom: '1rem',
                            color: 'var(--bosscat-black-400)',
                          }}
                        >
                          Brand
                        </Typography>
                      </Box>
                      <Typography
                        align="center"
                        className={`${styles.title} ${styles.noTransform}`}
                        style={{ marginBottom: '1rem' }}
                      >
                        {info.brand}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" style={{ gap: '.4rem' }}>
                        <FlipToFront style={{ color: 'var(--bosscat-black-400)' }} />
                        <Typography
                          align="center"
                          className={`${styles.title} ${styles.noTransform}`}
                          style={{
                            marginBottom: '1rem',
                            color: 'var(--bosscat-black-400)',
                          }}
                        >
                          Model
                        </Typography>
                      </Box>
                      <Typography
                        align="center"
                        className={`${styles.title} ${styles.noTransform}`}
                        style={{ marginBottom: '1rem' }}
                      >
                        {info.model}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" style={{ gap: '.4rem' }}>
                        <FlipToBack style={{ color: 'var(--bosscat-black-400)' }} />
                        <Typography
                          align="center"
                          className={`${styles.title} ${styles.noTransform}`}
                          style={{
                            marginBottom: '1rem',
                            color: 'var(--bosscat-black-400)',
                          }}
                        >
                          Serial #
                        </Typography>
                      </Box>
                      <Typography
                        align="center"
                        className={`${styles.title} ${styles.noTransform}`}
                        style={{ marginBottom: '1rem' }}
                      >
                        {info.serial}
                      </Typography>
                    </Box>
                    <UIButton
                      fullWidth
                      style={{
                        borderRadius: '8px',
                        backgroundColor: 'var(--bosscat-green-600)',
                      }}
                      onClick={handleConfirmAppliance}
                      disabled={loadingConfirm}
                      startIcon={
                        !loadingConfirm && <CheckCircle
                          style={{ color: '#FFF', width: '16px', height: '16px' }}
                        />
                      }
                    >
                      {loadingConfirm ? <Spinner color={"#fff"} size={16} /> : <Typography
                        className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                        style={{ color: '#FFFFFF' }}
                      >
                        Upload Appliance Tag
                      </Typography>}
                    </UIButton>
                    <Box className={styles.boxQuestion}>
                      <Typography
                        align="left"
                        className={`${styles.noTransform}`}
                        style={{
                          marginBottom: '.3rem',
                          fontWeight: 800,
                        }}
                      >
                        Is something incorrect?
                      </Typography>
                      <Typography
                        align="left"
                        className={`${styles.title} ${styles.noTransform}`}
                        style={{
                          marginBottom: '1rem',
                          color: 'var(--bosscat-black-400)',
                        }}
                      >
                        Edit the data returned or upload a new image to be scanned by
                        our technology.
                      </Typography>
                      <Box className={styles.buttonsContainer}>
                        <UIButton
                          fullWidth
                          variant="outlined"
                          style={{ borderRadius: '8px' }}
                          onClick={cancelUpload}
                          disabled={loadingConfirm}
                          startIcon={
                            <CameraAlt style={{ width: '16px', height: '16px' }} />
                          }
                        >
                          <Typography
                            className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                          >
                            Upload New
                          </Typography>
                        </UIButton>
                        <UIButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{ borderRadius: '8px' }}
                          onClick={editAppliance}
                          disabled={loadingConfirm}
                          startIcon={<Edit style={{ width: '16px', height: '16px' }} />}
                        >
                          <Typography
                            className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                          >
                            Edit data
                          </Typography>
                        </UIButton>
                      </Box>
                    </Box>
                  </Box>
                ) : editMode ? (
                  <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ gap: '8px', marginTop: '1rem' }}
                    >
                      <Typography variant="subtitle2" style={{ fontWeight: 800 }}>
                        Brand
                      </Typography>
                      <STextField
                        value={info?.brand}
                        className={styles.applianceTextField}
                        onChange={(event) =>
                          setInfo({ ...info, brand: event.target.value })
                        }
                        placeholder="Brand"
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
                      <Typography variant="subtitle2" style={{ fontWeight: 800 }}>
                        Model
                      </Typography>
                      <STextField
                        value={info?.model}
                        className={styles.applianceTextField}
                        onChange={(event) =>
                          setInfo({ ...info, model: event.target.value })
                        }
                        placeholder="Model"
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
                      <Typography variant="subtitle2" style={{ fontWeight: 800 }}>
                        Serial #
                      </Typography>
                      <STextField
                        value={info?.serial}
                        className={styles.applianceTextField}
                        onChange={(event) =>
                          setInfo({ ...info, serial: event.target.value })
                        }
                        placeholder="Serial number"
                      />
                    </Box>
                    <Box className={styles.buttonsContainer}>
                      <UIButton
                        fullWidth
                        variant="outlined"
                        style={{ borderRadius: '8px' }}
                        onClick={cancelUpload}
                        disabled={loadingConfirm}
                      >
                        <Typography
                          className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                        >
                          Cancel
                        </Typography>
                      </UIButton>
                      <UIButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: '8px' }}
                        onClick={handleConfirmAppliance}
                        disabled={loadingConfirm}
                      >
                        {loadingConfirm ? <Spinner
                          color={"#fff"}
                          size={16}
                        />
                          : <Typography
                            className={`${styles.title} ${styles.noTransform} ${styles.newApplianceText}`}
                          >
                            Save & confirm
                          </Typography>}
                      </UIButton>
                    </Box>
                  </Box>
                ) : null}
              </Grid>}
    </Dialog>
  )
}

export default PhotoSelectedModal
