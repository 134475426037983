import { Box } from '@material-ui/core'


const ProgressBar = ({percentage}: {percentage: number}) => {

  return (
    <Box style={{ width: '100%', backgroundColor: 'var(--bosscat-grey-400)', height: '12px', borderRadius: '16px', position: 'relative' }}>
        <Box style={{ position: 'absolute', left: 0, width: `${percentage}%`, borderRadius: '16px', height: '100%', backgroundColor: 'var(--progress-color)'}}/>
    </Box>
  )
}

export default ProgressBar
