import { Button, Grid, Typography } from '@material-ui/core'
import { FC } from 'react'
import useStyles from '../styles'
import { newEstimate } from 'assets/index'
import FileDownloadIcon from '@material-ui/icons/GetApp'

interface ItemsDetailProps {
  title: string
  subTitle: string
  description: string
  buttonText: string
}

const ItemsDetail: FC<ItemsDetailProps> = ({ title, subTitle, description, buttonText }) => {
  const styles = useStyles()

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography className={styles.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid
          item
          container
          style={{ padding: '8px' }}
          className={styles.borderedBox}
          justifyContent="space-between"
        >
          <Grid item container spacing={2} style={{ width: 'fit-content' }}>
            <Grid item>
              <img
                src={newEstimate}
                style={{ width: '56px', height: '56px' }}
                alt="new estimate"
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              style={{ width: 'fit-content', gap: '4px' }}
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  style={{
                    fontFamily: 'OutfitNormal',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                >
                  {subTitle}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={styles.greyTitle}>{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
         {/*  <Grid item>
            <Button
              className={styles.greyButton}
              style={{ padding: '0 16px' }}
              endIcon={
                <FileDownloadIcon
                  style={{ width: '16px', height: '16px' }}
                  color="inherit"
                />
              }
            >
              <Typography className={styles.primaryLatoText}>
                {buttonText}
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ItemsDetail
