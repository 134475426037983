import { authTypes } from 'ducks/auth/types'
import { PropertySource7 } from 'ducks/types'
import { PropertyDetailType, PropertyDetailsActions, propertyDetailType } from './types'

const initialState: PropertyDetailType = {} as PropertyDetailType

const reducer = (state = initialState, action: PropertyDetailsActions): PropertyDetailType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case propertyDetailType.SET_PROPERTY_DETAIL: {
      return { ...state, ...payload as any }
    }

    case propertyDetailType.SET_PROPERTY_SOURCE7: {
      return { ...state, propertySource7: payload as PropertySource7 | undefined }
    }

    case propertyDetailType.SET_APPLIANCES_TYPES: {
      return { ...state, appliancesTypes: payload as any }
    }

    case propertyDetailType.SET_APPLIANCES_BRANDS: {
      return { ...state, appliancesBrands: payload as any }
    }

    case propertyDetailType.RESET_PROPERTY_DETAIL: {
      return initialState
    }

    case propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS: {
      return { ...state, inquiredItems: payload as string[] }
    }

    default:
      return state
  }
}

export default reducer
