import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    homeownerInformation: {
      gap: '1rem',
    },
    title: {
      color: 'var(--head-text-color)',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
    titleWithoutMarging: {
      color: 'var(--head-text-color)',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: '18px',
      lineHeight: '24px',
    },
    subtitle: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      marginBottom: '16px',
      color: 'var(--head-text-color)',
    },
    input: {},
    addButton: {
      background: '#FCFCFC',
      height: 'fit-content',
      textTransform: 'none',
      fontFamily: 'LatoBold',
      padding: '0.5rem 1rem',
      marginTop: '1rem',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      marginLeft: '.2rem',
    },
    latoPrimaryText: {
      fontFamily: 'LatoBold',
    },
  })
)
