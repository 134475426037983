import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '640px',
      borderRadius: '32px',
      textAlign: 'center'
    },
    title: {
      fontSize: '20px',
      //padding: '0 4px 0 24px',
      // padding: '0 20px',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontWeight: 700,
      // textAlign: 'center',
    },
    title2: {
      fontSize: '30px',
      padding: '0 24px 0 4px',
      fontFamily: 'Lato',
      fontWeight: 400,
      color: 'var(--bosscat-blue-600) !important'
    },
    text: {
      fontSize: '20px',
      padding: '0 20px',
      fontFamily: 'LatoNormal',
      color: "#1A2A55"
    },
    image: {
      width: '192px',
      height: '192px',
      marginBottom: '12px'
    },
    submit: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'BetterSansRegular',
      '&:hover': {
        color: 'var(--progress-color-back) !important',
        backgroundColor: 'var(--button-focus-color)'
      }
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
      justifyContent: 'flex-end'
    },
    titles: {
      display: 'flex',
      width: '100%',
    },
    buttonClose: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: '#FAFBFF',
      borderRadius: 8,
      height: '56px',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      width: '100%',

      '&:hover': {
        color: 'var(--bosscat-blue-600)',
        backgroundColor: '#FAFBFF'
      }
    },
    left: {
      textAlign: 'left',
    },
    buttonRemove: {
      textTransform: 'none',
      color: '#FFFFFF',
      backgroundColor: '#EB5151',
      borderRadius: 8,
      height: '56px',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      width: '100%',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#EB5151'
      }
    },
    titleContact: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    },
    iconLabel: {
      display: 'flex',
      gap: '.5rem',
      '@global': {
        '.MuiSvgIcon-root': {
          width: 14
        }
      }
    },
    container: {
      background: '#F9FAFA',
      padding: '.5rem',
      borderRadius: 8
    },
    consent: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      marginTop: '15px',
      flexWrap: 'nowrap'
    },
  })
)
