import { Dialog, Grid, Typography } from '@material-ui/core'
import { homedepotLogo } from 'assets/index'
import React from 'react'
import styles from './styles.module.scss'
import { Loader } from 'components/UI'


interface OrderReplacementModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

const OrderReplacementModal: React.FC<OrderReplacementModalProps> = ({
    open,
    setOpen,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
            fullWidth
        >
            <Grid container direction="column" alignItems='center'>
                <img alt="homedepot logo" src={homedepotLogo} className={styles.logo} />
                <Typography align='center'>
                    You’re going to be redirected to The Home Depot to order your replacement.
                </Typography>
                <Loader />
            </Grid>
        </Dialog>
    )
}

export default OrderReplacementModal