import React from "react";
import styles from "./styles.module.scss"; // CSS externo para estilos

const RiskScoreHorizontal = ({ score = 0 }: { score: number }) => {
    // Determinar el nivel de riesgo basado en el puntaje
    const getRiskLevel = (score: number) => {
        if (score <= 2) return "Low Risk";
        if (score <= 4) return "Moderate Risk";
        if (score <= 6) return "High Risk";
        return "Critical Risk";
    };

    const riskLevel = getRiskLevel(score);

    return (
        <div className={styles.riskScoreContainer}>
            <div className={styles.progressBar}>
                <div
                    className={styles.marker}
                    style={{ left: `${(score / 10) * 100}%` }}
                ></div>
            </div>
            <p className={styles.riskScoreText}>
                Risk Score is <span className={styles.score} style={{
                    color: score <= 2 ? "#34BB7A" : score <= 4 ? "#FFC531" : "#CC4027"
                }}>{score}</span> ({riskLevel}).
            </p>
        </div>
    );
};

export default RiskScoreHorizontal;