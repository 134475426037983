import { Theme as ThemeMUI, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      padding: '8px 0',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#6D6A6F',
      maxWidth: 550
    },
    icon: {
      maxWidth: 170,
      width: '100%'
    },
    text: {
      padding: '0',
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      textAlign: 'left'
    },
    title: {
      color: '#01AA59',
      textAlign: 'center',
      // fontFamily: 'OutfitHeavy',
      fontWeight: 900,
      fontSize: 22
    },
    shoppingBox: {
      display: 'flex',
      backgroundColor: '#E6F7EE',
      padding: '.5rem',
      borderRadius: 8,
      gap: '.5rem',
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 700,
          color: '#004424'
        }
      }
    }
  })
)
