import { Box, Typography } from '@material-ui/core'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { Appliance } from 'ducks/types'
import Button from '../Button'
import styles from './styles.module.scss'

type Props = {
    appliance: Appliance
    handleOrderReplacement: () => void
    handleScheduleService: () => void
    handleOpenDetail: (appliance: Appliance) => void
}

const ApplianceItemMobile = ({ appliance, handleOrderReplacement, handleScheduleService, handleOpenDetail }: Props) => {
    return (
        <Box onClick={() => handleOpenDetail(appliance)} className={styles.applianceContainer}>
            <Box className={styles.header}>
                <Box className={styles.headerLeft}>
                    <Typography className={styles.name}>{appliance?.images[0]?.vision_data?.product_type_name ||
                        appliance.product_type_name}</Typography>
                    <Typography className={styles.brandName}>{appliance.brand_name || appliance?.images[0]?.vision_data?.brand_name}</Typography>
                </Box>
                <Box className={styles.headerRight}>
                    <Typography>View Report</Typography>
                    <ArrowForward fontSize="small" />
                </Box>
            </Box>
            <Box className={styles.ageInfo}>
                <Box className={styles.ageInfoCol}>
                    <Typography className={styles.ageValue}>{`${Math.floor(appliance.expected_life)} ${Math.floor(appliance.expected_life) === 1 ? 'year' : 'years'}` || '-'}</Typography>
                    <Typography className={styles.ageLabel}>Expected Life</Typography>
                </Box>
                <Box>
                    <Typography className={styles.ageValue}>{`${Math.floor(appliance.age)} ${Math.floor(appliance.age) === 1 ? 'year' : 'years'}` || '-'}</Typography>
                    <Typography className={styles.ageLabel}>Product Age</Typography>
                </Box>
                <Box>
                    <Typography className={styles.ageValue}>{`${Math.floor(appliance.remaining_life)} ${Math.floor(appliance.remaining_life) === 1 ? 'year' : 'years'}` || '-'}</Typography>
                    <Typography className={styles.ageLabel}>Est. Remaining Life</Typography>
                </Box>
            </Box>
            <Box className={styles.buttons}>
                <Button backgroundColor='#EAF2FE' labelColor='#1C4A94' onClick={handleScheduleService} label='Schedule Service' />
                <Box style={{ height: "8px" }} />
                <Button backgroundColor='#E6F7EE' labelColor='#01AA59' onClick={handleOrderReplacement} label='Order Replacement' startIcon={<ShoppingCart htmlColor='#01AA59' />} />
            </Box>
        </Box>
    )
}

export default ApplianceItemMobile