import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      // alignItems: 'center',
      justifyContent: 'center'
    },
    radioContainer: {
      gap: '1rem',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    radio: {
      textTransform: 'capitalize',
      width: '50%',
      padding: '0 8px !important',
      height: '54px'
    },
    label: {
      paddingBottom: '8px',
      fontFamily: 'LatoBlack',
      fontWeight: 900,
      fontSize: "18px",
      color: "#0B060F"
    },
    bold: {
      fontFamily: 'OutfitBold !important',
      textTransform: 'uppercase',
    },
    content: {
      maxWidth: theme.breakpoints.values.lg,
      margin: 'auto 0px',
      height: '100%',
      paddingRight: '24px'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingRight: "0 !important",
      }
    },
    containerFormMax: {
      //maxWidth: '660px',
      width: '100%'
    },
    containerForm: {
      width: '100%'
    },
    acceptText: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    buttonsContainer: {
      position: "sticky",
      bottom: 0
    },
    preferredContainer: {
      [theme.breakpoints.down('sm')]: {
        gap: "1rem 0.25rem"
      }
    },
    legalContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#EAF2FE',
      padding: "8px",
      borderRadius: "8px",
      justifyContent: 'start',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap'
      }
    },
    legalLink: {
      fontSize: 14,
      fontFamily: 'LatoBold',
      color: "#2F7BF7",
      marginLeft: 2,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',

    },
    checkTerms: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'LatoBold',
      margin: 0
    },
    center: {
      backgroundColor: '#FFFFFF',
      padding: '0 20px'
    },
    title: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      fontSize: 28,
      color: "#A5965A",
      width: '100%',
      margin: '2rem 0 0.8rem 0',

      '@global': {
        span: {
          fontFamily: 'LatoNormal !important'
        }
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px'
      }
    },
    subtitle: {
      fontFamily: 'LatoBlack !important',
      margin: '0.8rem 0',
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    titleContact: {
      fontFamily: 'LatoBold',
      fontSize: '24px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    },
    textBlack: {
      fontFamily: 'Lato !important',
      fontWeight: 700,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '20px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
        fontSize: '18px !important'
      }
    },
    text: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem'
      }
    },
    text2: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '16px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
        fontSize: '16px !important'
      }
    },
    bottom: {
      marginBottom: '0.8rem'
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonAdd: {
      margin: '16px 0px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      border: '1px solid var(--bosscat-blue-600)',
      padding: '.2rem 1rem'
    },
    buttonRemove: {
      margin: '16px 0px',
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: '#E01F1F;',
      backgroundColor: '#FFF4F4',
      borderRadius: 8,
      border: '1px solid #FFF4F4',
      textAlign: 'right',
      '&:hover': {
        color: '#E01F1F',
        backgroundColor: '#FFF4F4',
        border: '1px solid #EB5151'
      }
    },
    check: {
      flex: 1,
      paddingRight: '16px',
      marginRigth: '16px !important',
      marginLeft: '0 !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      '&:last-child': {
        marginRight: 0
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 5px !important',
      }
    },
    checkConsent: {
      fontFamily: 'LatoNormal'
    },

    consent: {
      flexWrap: 'nowrap',
      backgroundColor: '#FCFCFC',
      borderRadius: 8,
      padding: '1rem',
      marginRight: '1rem',
    },
    homeOwnerConsent: {

    },
    homeOwnerPreferredMethod: {
      margin: '3rem 0'
    },
    noContactsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    noContactsImage: {
      marginBottom: '.5rem',
    },
    noContactsSubtitle: {
      width: '20%',
      fontWeight: 400,
      textAlign: 'center',
    },
    /*     noContactsImageRelative:{
          '&::before': {
            content: '""',
            backgroundImage: 'url(/no-contacts-arrow.svg)',
            display: 'block',
            position: 'absolute',
            height: 60,
            marginTop: -60
          }
        }, */
    noContactsArrow: {

    },
    buttonsCheck: {
      display: "flex", alignItems: "center", marginTop: "1rem",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        alignItems: "flex-start",
      }
    },
    accountContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      border: "2px solid #99DDBD",
      borderRadius: "8px",
      padding: "8px 16px",
      marginTop: "18px"
    },
    accountCopy: {
      fontFamily: 'LatoNormal',
      fontSize: 18,
      color: "#1A2A55"
    }
  })
)
