import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridTemplateRows: 'min-content auto min-content',
      margin: 'auto 0',
      paddingRight: '24px',
      '@global': {
        '.MuiTypography-root': {
          fontFamily: 'LatoBold'
        }
      }
    },
    backButton: {
      position: 'absolute',
      left: '4px',
      top: '0px',
      height: '44px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      margin: 'auto',
      minHeight: '50vh',
      height: '58vh',
      [theme.breakpoints.down('sm')]: {
        height: '18vh'
      }
    },
    lgFont: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    tab: {
      fontFamily: "LatoBold !important"
    },
    title: {
      fontFamily: 'OutfitHeavy !important',
      textTransform: 'uppercase',
      fontSize: 28,
      color: '#A5965A',
      margin: "0.8rem 0 0.8rem 0",
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem'
      }
    },
    dropzone: {
      minHeight: '300px !important',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px !important'
      },
      '@global': {
        '.MuiDropzoneArea-text': {
          fontSize: 16
        }
      }
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    multilineText: {
      minHeight: '300px !important',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px !important'
      }
    },
  })
)
