import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: 'nowrap',
      border: '1px solid #EBECEF',
      borderRadius: 8,
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        // flexDirection: 'column',
        gap: '1rem',
        padding: '.7rem',
      }
    },
    container: {
      flexWrap: 'nowrap',

    },
    titleContact: {
      fontFamily: 'OutfitBold',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    },
    left: {

    },
    right: {
      width: 'fit-content',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        gap: '1rem'
      }
    },
    icon: {
      width: 40,
      height: 40,
      padding: '1rem',
      backgroundColor: '#FAFBFF',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '@global': {
        '.MuiSvgIcon-root': {
          fill: 'var(--bosscat-blue-600)',
        },
        '.MuiButtonBase-root:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    iconRemove: {
      width: 40,
      height: 40,
      padding: '1rem',
      backgroundColor: '#FFF4F4',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '@global': {
        '.MuiSvgIcon-root': {
          fill: '#E01F1F',
        },
        '.MuiButtonBase-root:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    roleTag: {
      textTransform: 'capitalize',
      padding: '.3rem !important',
      fontWeight: 700,
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 700,
          fontSize: 14
        }
      }
    },
    homeowner: {
      color: 'var(--completed-color)',
      backgroundColor: 'var(--completed-color-back)',
      textAlign: 'center',
      borderRadius: '8px',
      width: 'fit-content !important',
    },
    homebuyer: {
      color: '#0071A3',
      backgroundColor: '#FAFDFF',
      textAlign: 'center',
      borderRadius: '8px',
      width: 'fit-content !important',
    },
    role: {
    },
    actions: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      gap: '1.5rem',
      marginTop: '1rem',
      paddingBottom: '.5rem'
    },
    buttonEdit: {
      margin: '16px 0px',
      textTransform: 'none',
      backgroundColor: 'white',
      borderRadius: 8,
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'Lato',
      fontWeight: 500,
      height: 'fit-content',
      width: '100%',
      padding: '.7rem 1rem'
    },
    buttonRemove: {
      margin: '16px 0px',
      textTransform: 'none',
      backgroundColor: '#FFEEEA',
      borderRadius: 8,
      color: 'var(--punchlist-red)',
      fontFamily: 'Lato',
      fontWeight: 500,
      height: 'fit-content',
      width: '100%',
      padding: '.7rem 1rem'
    },
    removeIcon: {
      fill: 'var(--punchlist-red) !important',
    },
    iconLabel: {
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#6D6A6F',
      display: 'flex',
      gap: '.5rem',
      '@global': {
        '.MuiSvgIcon-root': {
          width: 14
        }
      }
    }
  })
)
