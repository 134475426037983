import { FC, useState } from 'react'

import useStyles from './styles'
import { Box, Button, Typography } from '@material-ui/core'
import { calcWeekends, getHolidaysDate, getNextBusinessDayIfFriday, isEmpty } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate, getUser } from 'ducks/selectors'
import { Item } from 'ducks/types'
import { houseInfrastructure, icons } from 'assets'
import { SingleDatePicker } from 'components/UI/CustomUI/atoms/DatePicker/SingleDatePicker'
import { clientQuestionsActions } from 'ducks/actions'
import { COMMUNICATION_METHODS } from 'ducks/clientQuestions/types'
import moment from 'moment'
import { addDays } from 'date-fns'

export interface HomeConsultationModalProps {
  selectedItemsForConsultation?: Item[],
  onSuccess: () => void
}

const HomeConsultationModal: FC<HomeConsultationModalProps> = ({ selectedItemsForConsultation, onSuccess }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [date, setDate] = useState<Date>()
  const [showSuccess, setShowSuccess] = useState(false)
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)
  const holidays = getHolidaysDate()
  const nextDay = addDays(new Date(), 1)
  const weekends = calcWeekends(new Date(), addDays(new Date(), 400))

  const nextBusinessDayIfFriday = getNextBusinessDayIfFriday()

  const disabledDates = [...weekends, ...holidays, new Date(), nextDay]

  if (nextBusinessDayIfFriday) {
    disabledDates.push(nextBusinessDayIfFriday)
  }

  const handleChangeDate = (value: Date) => {
    setDate(value)
  }

  const handleSubmit = () => {
    dispatch(clientQuestionsActions.createClientQuestion({
      estimate_id: estimate?.id || '',
      communication_method: COMMUNICATION_METHODS.EMAIL,
      item_ids: selectedItemsForConsultation?.map(item => item.itemId),
      requested_date: moment(date)?.format('MM/DD/YYYY'),
      estimate_contact_id: user.id,
      question: ''
    }, (succ) => {
      onSuccess()
      succ && setShowSuccess(true)
    }))
  }

  if (showSuccess) return (
    <Box className={classes.successContainer}>
      <img src={houseInfrastructure} alt="House infrastructure" />
      <Typography className={classes.titleSuccess}>In-home consultation requested!</Typography>
      <Typography className={classes.subtitleSuccess}>Our team will reach out to confirm scheduling.</Typography>
      <Box className={classes.shoppingBox}>
        <icons.ShoppingCart htmlColor="#018847" style={{ width: '13px' }} />
        <Typography>In the meantime, you can continue to add items to your cart and place an order.</Typography>
        <icons.ArrowForward htmlColor="#018847" style={{ width: '13px' }} />
      </Box>
    </Box>
  )

  return (
    <Box className={classes.root}>
      <Box style={{ margin: '1rem 0' }}>
        <Typography variant='h6' style={{ paddingLeft: 0, fontFamily: 'OutfitHeavy', textTransform: 'uppercase', }}>schedule in-home consultation ({selectedItemsForConsultation?.length}) items</Typography>
        <Typography style={{ color: '#6D6A6F' }}>Request the date that best suits your schedule.</Typography>
      </Box>
      <Box className={classes.datePickerContainer}>
        <SingleDatePicker
          onChange={handleChangeDate}
          value={date as Date}
          minDate={new Date()}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
          disabledDates={disabledDates}
        />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.button}
          onClick={handleSubmit}
          disabled={isEmpty(date?.toString())}
        >Request Consultation</Button>
      </Box>

    </Box>
  )
}

export default HomeConsultationModal
