import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './styles.module.scss'
import Spinner from '../SpinnerLoader'

type Props = {
    onClick: () => void
    label: string
    disabled?: boolean
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    labelColor?: string
    backgroundColor?: string
    loading?: boolean
    width?: string;
}

const Button = ({
    onClick, label, disabled, startIcon, endIcon, labelColor = 'white', backgroundColor = "var(--bosscat-blue-600)", loading, width = "100%"
}: Props) => {
    const handleClick = () => {
        if (disabled) return
        if (loading) return
        onClick()
    }
    return (
        <div className={`${styles.button}`} onClick={handleClick} style={{ cursor: disabled ? "not-allowed" : 'pointer', backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : backgroundColor, width }}>
            {loading ? <Spinner color={labelColor} size={16} /> : <>
                {startIcon && startIcon}
                <Typography style={{ color: labelColor, fontFamily: 'LatoBold', marginLeft: "8px", textAlign: "center" }}>
                    {label}
                </Typography>
                {endIcon && endIcon}
            </>}
        </div>
    )
}

export default Button