import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'baseline',
      color: theme.palette.primary.dark
    },
    radioContainer: {
      gap: '1rem',
    },
    options: {
      padding: '16px 0',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '4rem'
      }
    },
    radio: {
      //textTransform: 'capitalize'
    },
    option: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
        borderRadius: '16px',
        cursor: 'pointer',
      },
      margin: '4px 0',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    title: {
      fontFamily: 'OutfitHeavy',
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      }
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 600,
      marginTop: '1rem'
    },
    icon: {
      margin: '0 0 2px 0',
      width: '12px',
      height: '12px'
    },
    selected: {
      background: '#CEF0FF !important',
      boxSizing: 'border-box',
      borderRadius: '16px',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    iconJob: {
      margin: '8px',
      height: '32px',
      width: '32px',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    smallInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: 'var(--date-color)',
      margin: '8px',
    },
    cancel: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    },
    submit: {
      // margin: theme.spacing(3, 0, 4),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    },

  })
)