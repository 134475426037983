import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 24,
      float: 'right',
      margin: '2px 16px 0'
    },
    itemsBox: {
      padding: '8px 0 32px'
    },
    title: {
      display: 'block',
      textAlign: 'center',
      cursor: 'pointer',
      padding: '8px 0',
      width: '100%',
      borderRadius: '8px'
    },
    itemTitle: {
      fontFamily: 'Outfit',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '150%',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem'
      }
    },
    items: {
      padding: '0 8px'
    },
    needsAttention: {
      color: 'var(--expired-color)',
      backgroundColor: 'var(--expired-color-back)'
    },
    completed: {
      color: 'var(--completed-color)',
      backgroundColor: 'var(--completed-color-back)'
    },
    inProgress: {
      color: 'var(--progress-color)',
      backgroundColor: 'var(--progress-color-back)'
    },
    scheduled: {
      color: 'var(--hardware-haze-600)',
      backgroundColor: 'var(--scheduling-color-back)'
    },
    hold: {
      color: 'var(--hold-color)',
      backgroundColor: 'var(--hold-color-back)'
    },
    dispatched: {
      color: 'var(--dispatched-color)',
      backgroundColor: 'var(--dispatched-color-back)'
    }
  })
)
