import { Dialog, Grid, Typography } from '@material-ui/core'
import { searsLogo } from 'assets/index'
import React from 'react'
import styles from './styles.module.scss'
import { Loader } from 'components/UI'


interface ScheduleServiceModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

const ScheduleServiceModal: React.FC<ScheduleServiceModalProps> = ({
    open,
    setOpen,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ style: { borderRadius: '16px', padding: '16px' } }}
            fullWidth
        >
            <Grid container direction="column" alignItems='center'>
                <img alt="sears logo" src={searsLogo} className={styles.logo} />
                <Typography align='center'>
                    You’re going to be redirected to Sears Home Services to schedule your Maintenance Service.
                </Typography>
                <Loader />
            </Grid>
        </Dialog>
    )
}

export default ScheduleServiceModal