import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100vw',
      paddingTop: '35px',
    },
    nextHeavy: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
    },
    subNextHeavy: {
      fontFamily: 'LatoBold',
      fontSize: 20,
      color: "#3574e3"
    },
    nextHeavyHardware: {
      fontFamily: 'LatoBold',
      color: '#444444',
      fontSize: "1.75rem",
      textAlign: 'center',
      margin: "4rem 0 3rem 0"
    },
    nextHeavyHardware2: {
      fontFamily: 'OutfitHeavy',
      textTransform: 'uppercase',
      color: '#A5965A',
      fontSize: 24,
      textAlign: 'center',
    },
    subText: {
      fontSize: 16,
      fontFamily: 'Lato',
      textAlign: 'center',
    },
    subText2: {
      fontSize: 12,
      fontFamily: 'Lato',
      textAlign: 'center',
    },
    banner2: {
      background: "#f5f6f8",
      paddingBottom: "5rem"
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto 0px',
    },
    container2: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
      },
    },
    item: {
      flexBasis: '20%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    button: {
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '94%',
        position: 'fixed',
        bottom: 0,
      },
      marginTop: '2rem',
      marginBottom: '10px',
    },
    title: {
      fontSize: 40,
      textAlign: 'center',
      color: "#0B060F",

    },
    bottom: {
      marginBottom: '0.8rem'
    },
    top: {
      marginTop: '0.8rem'
    },
    logoimg: {
      height: '29px',
    },
    images: {
      // height: '121px',
      width: '118px',
    },
    marginItem: {
      marginBottom: '2rem',
    },
    video: {
      width: '540px',
      height: '304px',
      border: "none",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      }
    },
    banner1_Container: {
      paddingTop: '140px',
      width: '100vw',
      minHeight: '110vh',
      background: "linear-gradient(to right, rgba(0, 93, 172, .8), rgba(0, 93, 172, .8))",
      color: "#fff",
      paddingBottom: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        paddingTop: "6rem",
        paddingBottom: "6rem"
      }
    },
    banner1_Body: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    banner1_BodyRightButton: {
      width: "100%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#4285f4!important",
      color: "#fff",
      cursor: "pointer",
      padding: "1rem 2.4rem",
      fontSize: ".94rem"
    },
    bannerEstimate_BodyLeft: {
      width: "50%",
      padding: "0 1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    banner1_BodyLeft: {
      width: "50%",
      padding: "0 1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    banner1_Loading: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    banner1_BodyRight: {
      width: "50%",
      padding: "0 1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    bannerEstimate_BodyRight: {
      width: "50%",
      padding: "0 1rem",
      color: "#4f4f4f",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    banner1_BodyRightInner: {
      borderTopRightRadius: "30px",
      borderBottomLeftRadius: "30px",
      padding: "3rem",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      backgroundClip: "border-box",
      borderRadius: "0.25rem"
    },
    banner1_LeftTitle: {
      fontSize: "44px",
      margin: "2rem 0",
      lineHeight: "1.1",
      fontFamily: "LatoBold"
    },
    banner1_List: {
      lineHeight: "1.5",
      fontSize: "18px",
    },
    banner1_ptpLogo: {
      width: "140px",
      height: "50px",
    },
    headerLogo: {
      width: "146px",
      height: "34px",
    },
    header: {
      width: "100%",
      height: "70px",
      position: "fixed",
      zIndex: 10,
      top: 0,
      left: 0,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      background: "#fff",
      display: "flex",
      alignItems: "center",
    },
    headerInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2rem"
      }
    },
    maxWidth: {
      maxWidth: "1140px",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "95vw",
      }
    },
    banner1_BodyRightTitle: {
      fontSize: "1.75rem",
      color: "#4285f4!important",
      fontFamily: "LatoBlack",
      textAlign: "center",
      marginBottom: "0px"
    },
    banner1_BodyRightAddressLine1: {
      fontFamily: "LatoBold",
      color: "#7c8994 !important",
      fontSize: "1.75rem",
      textAlign: "center",

    },
    banner1_BodyRightAddressLine2: {
      fontFamily: "LatoNormal",
      color: "#7c8994 !important",
      fontSize: "1rem",
      textAlign: "center",
    },
    banner1_BodyRightBlock1: {
      paddingBottom: "2rem",
      borderBottom: "1px solid rgba(0,0,0,.1)",
    },
    banner1_BodyRightInput: {
      border: "none",
      borderBottom: "1px solid rgba(0,0,0,.1)",
      width: "100%",
      "&:focus-visible": {
        outline: "none",
        borderBottom: "1px solid rgba(0,0,0,.1)",
      },
      "&:focus": {
        border: "none",
        borderBottom: "1px solid rgba(0,0,0,.1)",
      },
      "&::placeholder": {
        fontSize: "1rem",
        color: "rgba(26, 42, 85, 0.45)",
      }
    },
    banner1_BodyRightInput_half: {
      border: "none",
      borderBottom: "1px solid rgba(0,0,0,.1)",
      width: "45%",
      "&:focus-visible": {
        outline: "none"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "54px",
        padding: 0
      }
    },
    banner1_BodyRightInput_error: {
      borderBottom: "1px solid #EB5151"
    },
    banner1_BodyRightBlock2: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem"
    },
    banner1_BodyRightConfirmAddress: {
      fontSize: "18px",
      color: "#616e79 !important",
      textAlign: "center",
      marginTop: "16px",
      paddingInline: "2rem"
    },
    banner1_BodyRightConfirmAddressQuestion: {
      color: "#007bff",
      fontSize: "14.4px",
      marginTop: "4px",
      textAlign: "center",
      cursor: "pointer",
    },
    banner1_BodyRightSubmit: {
      fontSize: "16px",
      fontFamily: "LatoBlack",
      color: "#fff",
    },
    banner1_BodyRightConfirmTerms: {
      color: "#6c757d!important",
      fontSize: "13px",
      textAlign: "center",
      lineHeight: "1.8",
      marginTop: "1.5rem"
    },
    banner3_Container: {
      paddingBlock: "70px",
      [theme.breakpoints.down("sm")]: {
        paddingInline: "16px",
      }
    },
    banner3_Block1: {
      marginBottom: "35px"
    },
    banner3_Title: {
      fontSize: "25px",
      color: "#444444",
      fontFamily: "LatoBold",
      marginBottom: "0.5rem"
    },
    banner3_Text: {
      fontSize: "18px",
      color: "#444444",
      lineHeight: "1.5",
    },
    question: {
      color: "#444",
      fontSize: "1.25rem",
      lineHeight: "1.2",
      fontFamily: "LatoNormal",
    },
    answer: {
      fontSize: ".9rem",
      color: "#626262",
      lineHeight: "1.7",
      marginBottom: "1rem",
      opacity: 0,
      transition: "all .3s ease-in-out",
    },
    answerActive: {
      opacity: 1,
    },
    questionRow: {
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      padding: "0.5rem 0 0 0",
    },
    bottomBorder: {
      borderBottom: "1px solid rgba(0,0,0,.1)",
      paddingInline: "1rem",
    },
    banner4_Container: {
      background: "#f5f6f8",
      paddingBlock: "70px",
    },
    banner4_Block1: {
      marginBottom: "35px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "2rem"
      }
    },
    banner4_Block1Left: {
      width: "50%",
      paddingInline: "1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    banner4_Block1Right: {
      width: "50%",
      paddingInline: "1rem",
      color: "#444444",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    banner4_Title: {
      fontSize: "24px",
      marginBottom: "0.5rem",
    },
    banner4_Text: {
      fontSize: "18px",
      marginBottom: "1rem",
    },
    banner4_List: {
      fontSize: "16px",
      lineHeight: "1.5",
    },
    banner5: {
      marginBlock: "70px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "#444444",
      paddingInline: "5rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingInline: "1rem",
        gap: "2rem"
      }
    },
    banner5_Img: {
      width: "125px",
      height: "125px",
    },
    banner5_Title: {
      fontSize: "2rem",
    },
    banner5_Text: {
      fontSize: "24px"
    },
    padding: {
      paddingInline: "1rem"
    },
    bannerEstimate_TopInformation: {
      width: "100%",
      border: "1px solid #eee",
      borderLeftWidth: "5px",
      borderLeftColor: "var(--bosscat-blue-600)",
      padding: "5px",
      borderRadius: "3px",
      color: "var(--bosscat-blue-600)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "3rem"
    },
    bannerEstimate_Body: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "3rem 15px 1.5rem 15px",
      background: "#fff",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    bannerEstimate_Title: {
      fontSize: "1.5rem",
    },
    bannerEstimate_ExpectedBy: {
      fontSize: "1.5rem",
      fontFamily: "LatoBold",
    },
    bannerEstimate_Inspection: {
      fontSize: "1.25rem",
    },
    bannerEstimate_Notified: {
      fontSize: "1.25rem",
      color: "#212121",
      marginTop: "1rem",
      marginBottom: "0.5rem"
    },
    bannerEstimate_ButtonNotify: {
      cursor: "pointer",
      borderRadius: "10em",
      color: "#fff",
      background: "var(--bosscat-blue-600)",
      margin: ".375rem",
      textTransform: "uppercase",
      textAlign: "center",
      border: 0,
      boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
      transition: "color .15s ease-in-out,opacity .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
      padding: ".84rem 2.14rem",
      fontSize: ".81rem",
      marginBottom: "1.5rem",
      "&:hover": {
        boxShadow: "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
      },
      "& .MuiCircularProgress-circle": {
        color: "#fff !important"
      }
    },
    bannerEstimate_BodyRightAddressLine1: {
      fontFamily: "LatoBold",
      fontSize: "2rem",
    },
    bannerEstimate_BodyRightAddressLine2: {
      fontFamily: "LatoBlack",
      fontSize: "1.5rem",
    },
    statusTag: {
      borderRadius: ".125rem",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
      padding: ".25em .4em",
      width: "fit-content",
      margin: ".5rem 0 1rem"
    },
    statusTagText: {
      fontSize: ".75rem",
      fontFamily: "LatoBlack",
    },
    inProgress: {
      color: "#fff",
      background: "#ff3547",
    },
    completed: {
      color: "#fff",
      background: "var(--bosscat-green-600)",
    },
    bannerEstimate_BodyRightExpected: {
      color: "#9e9e9e",
      fontSize: "1.25rem",
    },
    bannerEstimate_BodyRightExpectedDate: {
      color: "var(--bosscat-blue-600)",
      fontFamily: "LatoBold",
      fontSize: "1.75rem",
    },
    modal: {
      '@global': {
        '.MuiGrid-root': {
          width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: 'inherit'
        }
      }
    },
    modalContainer: {
      background: '#fff',
      minWidth: '500px',
      "&:focus-visible": {
        outline: "none"
      }
    },
    modalForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: "1.5rem",
      "&:focus-visible": {
        outline: "none"
      }
    },
    modalFormButtonDisabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    modalHeader: {
      background: "#4285f4",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
      padding: "1rem"
    },
    marginTop: {
      marginTop: "1rem",
      paddingLeft: 0,
      minHeight: "45px",
    },
    autocompleteRole: {
      border: "none",
      borderBottom: "1px solid rgba(0,0,0,.1)",
      borderRadius: 0,
      paddingInline: 0,
      marginBottom: "2rem",
      "&::placeholder": {
        fontSize: "1rem",
        color: "rgba(26, 42, 85, 0.45)",
      },
      "&:hover": {
        borderRadius: 0,
      }
    }
  })
)
