import { Property, Address, Insight } from 'ducks/types'

/** TYPES **/
export const propertiesTypes = {
  FETCH_PROPERTIES: 'FETCH_PROPERTIES',
  SET_PROPERTIES: 'SET_PROPERTIES',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  DELETE_PROPERTY: 'DELETE_PROPERTY',
  UPLOAD_PROPERTIES: 'UPLOAD_PROPERTIES',
  UPDATE_PROPERTY_PROJECT: 'UPDATE_PROPERTY_PROJECT',
  REQUEST_PROPERTY_RENOVATION: 'REQUEST_PROPERTY_RENOVATION',
  REQUEST_PROPERTY_ROOM: 'REQUEST_PROPERTY_ROOM'
}

/** ACTIONS **/
export interface FetchPropertiesAction {
  type: typeof propertiesTypes.FETCH_PROPERTIES
  payload: { state?: string, city?: string, zipCode?: string, archived?: boolean }
  callback?: (succ: boolean) => void
}

export interface SetPropertiesAction {
  type: typeof propertiesTypes.SET_PROPERTIES
  payload: Property[]
}

export interface CreatePropertyAction {
  type: typeof propertiesTypes.CREATE_PROPERTY
  payload: Partial<Property>
  callback: (succ: boolean, isDuplicated?: boolean) => void
}

export interface UpdatePropertyProjectAction {
  type: typeof propertiesTypes.UPDATE_PROPERTY_PROJECT
  payload: {
    propertyId: string
    projectName: string
    request: Partial<Insight>
  }
  callback: (succ: boolean) => void
}

export interface ConditionType {
  costBest: number
  costBetter: number
  costGood: number
  projectCount: number
  room: string
}
export interface ScanLabelImage {
  content: string;
  description: string;
  read_label: boolean;
}
export interface ConfirmAppliance {
  applianceId: string;
  brand_id: number;
  brand_name: string;
  model_number: string;
  productTypeId: number;
  serial_number: string;
  property_uid: string;
  propertyId: string;
  manufacturedYear?: number;
  manufacturedMonth?: number;
  install_date?: string;
  age?: number;
}
export interface ScanLabel {
  images?: ScanLabelImage[];
  product_type_id: number | string;
  propertyId: string;
  property_uid: string | null;
}
export interface RequestPropertyRenovationAction {
  type: typeof propertiesTypes.UPDATE_PROPERTY_PROJECT
  payload: {
    propertyId: string
    request: Partial<ConditionType>
  }
  callback: (succ: boolean) => void
}

export interface UpdatePropertyPayload {
  propertyId: string
  partialProperty: Partial<Property>
}


export interface UploadPropertyPayload {
  file: File
}

export interface UpdatePropertyAction {
  type: typeof propertiesTypes.UPDATE_PROPERTY
  payload: UpdatePropertyPayload
  callback: (succ: boolean) => void
}

export interface DeletePropertyAction {
  type: typeof propertiesTypes.DELETE_PROPERTY
  payload: string
  callback: (succ: boolean) => void
}

export interface UploadPropertiesAction {
  type: typeof propertiesTypes.UPLOAD_PROPERTIES
  payload: UploadPropertyPayload
  callback: (succ: boolean) => void
}

export type PropertiesActions =
  | FetchPropertiesAction
  | SetPropertiesAction
  | CreatePropertyAction
  | UpdatePropertyAction
  | DeletePropertyAction
  | UploadPropertiesAction
  | UpdatePropertyProjectAction
  | RequestPropertyRenovationAction

/** REDUCER **/
export interface PropertiesType extends Array<Property> { }
